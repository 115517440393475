import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";



export default class TopbarMenuOption extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { title, icon, onClick } = this.props;

        return (
            <a className="topbar__link"  href="#" onClick={onClick}>
                <span className={`topbar__link-icon lnr lnr-${icon}`} />
                <p className="topbar__link-title">{title}</p>
            </a>
        );
    }
}
