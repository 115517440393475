import React from 'react';
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import ReactHtmlParser from "react-html-parser";

const NoteDetailsModal = ({note,isOpen,deleteNote, downloadFile,toggleNoteModal}) => {

    return (

        <Modal className="MMP-modal" isOpen={isOpen} toggle={toggleNoteModal}  backdrop={true} keyboard={true}>
            <ModalHeader>
                <span className={`sidebar__link-icon lnr lnr-layers`} /> Notatka nr {(note && note.id) || ''}
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <Form>
                            <FormGroup className="row">
                                <Label className="col-lg-12 col-md-12">TREŚĆ NOTATKI</Label>
                            </FormGroup>
                        </Form>
                    </CardBody>
                    <CardBody>
                        {   ReactHtmlParser((note && note.text) || '' ) }
                    </CardBody>
                    <CardBody>
                        <Form>
                            <FormGroup className="row">
                                <Label className="col-lg-12 col-md-12">DANE FIRMY</Label>
                            </FormGroup>
                            <FormGroup className="row align-items-center">
                                <Label  className="col-lg-3 col-md-3">Nazwa firmy</Label>
                                <Col lg={9} md={9}>
                                    <Input  className="form-control" disabled={true} value={(note && note.company && note.company.name) || ''}/>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label  className="col-lg-3 col-md-3">Osoba kontaktowa</Label>
                                <Col lg={9} md={9}>
                                    <Input  className="form-control" disabled={true} value={(note && note.contactPerson && note.contactPerson.name+' '+note.contactPerson.surname) || ''}/>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label  className="col-lg-3 col-md-3">Data dodania</Label>
                                <Col lg={9} md={9}>
                                    <Input  className="form-control" disabled={true} value={(note && note.createdAt) || ''}/>
                                </Col>
                            </FormGroup>


                            <FormGroup className="row align-items-center">
                                <Label  className="col-lg-3 col-md-3">Załączniki</Label>
                                <Col lg={9} md={9}>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        {
                                            ((note && note.attachments)  || [])
                                                .map(a=>(
                                                    <div key={a.id}>
                                                        <span className="lnr lnr-paperclip"> <a onClick={(e)=>downloadFile(e, a.id,a.name)} href="#">{a.name}</a></span>
                                                    </div>

                                                ))

                                        }


                                    </div>
                                </Col>
                            </FormGroup>

                        </Form>
                    </CardBody>
                    <Row className="align-items-center justify-content-between top_buttons_container">
                        <Col md={6} lg={6} className="buttons_sub_container"  >
                            <div className="actions_button_container red"><a href="#" onClick={deleteNote} >Usuń</a></div>
                        </Col>
                    </Row>
                </Card>
            </ModalBody>
        </Modal>

    )

}

export  default NoteDetailsModal
