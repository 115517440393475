import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import TopbarMenuOption from "./TopbarMenuOption";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {

  logout = (e) => {
    if(localStorage.getItem('token') !== null){
      localStorage.clear();
    }
    window.location.href= '/log_in';
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{`${localStorage.getItem('name')} ${localStorage.getItem('surname')} `}</p>
          {/*<DownIcon className="topbar__icon" />*/}
          <span style={{fontSize:'20px',display:'inline-block'}} className="sidebar__category-icon lnr lnr-chevron-down" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Firmy" icon="apartment" path="/pages/companies" />
            <TopbarMenuLink title="Osoby kontaktowe" icon="users" path="/pages/contact-persons" />
            <TopbarMenuLink title="Notatki" icon="paperclip" path="/pages/notes" />
            <TopbarMenuLink title="Zadania" icon="layers" path="/pages/tasks/board" />
            <div className="topbar__menu-divider" />
            <TopbarMenuOption  title="Wyloguj się" icon="exit" onClick={this.logout} />
          </div>
        </Collapse>
      </div>
    );
  }
}
