import {TASK_ACTIONS} from "../actions/taskActions";


const initialState = {

    loading_files:false,
    loading_tasks:false,
    tasks:[],
    selectedTask:null,
    lastTaskId:-1,
    lastChangeId:-1,
    taskStatuses:[],
    owners:[],
    priorities:[],
    fileJustUploaded: false

};

export default function (state = initialState, action) {
    switch (action.type) {

        case TASK_ACTIONS.SET_ONE_TASK:

            return { ...state, selectedTask: action.task};

        case TASK_ACTIONS.SET_TASKS_LOADING:
            return { ...state, loading_tasks: action.tof};

        case TASK_ACTIONS.SET_FILES_LOADING:
            return { ...state, loading_files: action.tof};

        case TASK_ACTIONS.SET_ALL_TASKS:
            return { ...state, tasks: action.tasks};


        case TASK_ACTIONS.SET_LAST_TASK_ID:
            return { ...state, lastTaskId: action.id };


        case TASK_ACTIONS.SET_TASK_STATUSES:
            return { ...state, taskStatuses: action.statuses };

        case TASK_ACTIONS.SET_TASK_OWNERS:
            return { ...state, owners: action.owners };

        case TASK_ACTIONS.SET_TASK_PRIORITIES:
            return { ...state, priorities: action.priorities };

        case TASK_ACTIONS.SET_LAST_CHANGE_ID:
            return { ...state, lastChangeId: state.lastChangeId+1 };

        case TASK_ACTIONS.SET_LAST_FILE_UPLOADED:
            return { ...state, fileJustUploaded: action.tof };

        default:
            return state;
    }
}
