
import {applyMiddleware, combineReducers, createStore} from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  clientTypeReducer,
  companyReducer,
  groupReducer,
  sidebarReducer,
  tagReducer,
  themeReducer,
  brandReducer,
  statusReducer,
  contactPersonReducer,
  noteReducer,
  taskReducer

} from '../../redux/reducers/index';
import thunk from "redux-thunk";


const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  company: companyReducer,
  clientTypes:clientTypeReducer,
  tags:tagReducer,
  groups:groupReducer,
  brands:brandReducer,
  statuses:statusReducer,
  contactPersons:contactPersonReducer,
  notes:noteReducer,
  tasks:taskReducer
});

const store = createStore(reducer,applyMiddleware(thunk));

export default store;
