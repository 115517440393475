import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Header from "../../shared/header";
import ContactPersonEditCard from "./Components/ContactPersonEditCard";
import {apiCompanyGetAll, apiCompanyGetOne} from "../../redux/actions/companyActions";
import {
    apiContactPersonCreateNew, apiContactPersonDelete,
    apiContactPersonGetOne, apiContactPersonUpdate,
    contactPersonSetOne
} from "../../redux/actions/contactPersonActions";
import ContactPersonButtons from "./Components/ContactPersonButtons";
import Buttons from "../CompanyDetails/Components/Buttons";
import ListCard from "../CompanyDetails/Components/ListCard";
import ContactPersonListCard from "./Components/ContactPersonListCard";
import NotificationSystem from "rc-notification/es";
import {FullWideNotification} from "../../shared/components/Notification";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import Modal from "reactstrap/es/Modal";
import {apiNoteDelete} from "../../redux/actions/noteActions";
import {URL} from "../../redux/actions/constants";
import {downloadFile,arrayBufferToBase64} from '../../shared/functions/fileFunctions';
import ReactHtmlParser  from 'react-html-parser';


const mapStateToProps = (state) => {
    return {
        contactPerson: state.contactPersons.selectedContactPerson,
        contactPersons: state.contactPersons.contactPersons,
        errors: state.contactPersons.errors,
        companies: state.company.companies,
        selectedCompany: state.company.selectedCompany,
        success:state.contactPersons.success,
        loading:state.contactPersons.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        propApiCompanyGetOne: (id)=> dispatch(apiCompanyGetOne(id)),
        propApiCompanyGetAll: ()=> dispatch(apiCompanyGetAll()),
        propApiContactPersonGetOne: (id)=> dispatch(apiContactPersonGetOne(id)),
        propContactPersonSetOne: (contactPerson)=> dispatch(contactPersonSetOne(contactPerson)),
        propApiContactPersonUpdate: (contactPerson)=> dispatch(apiContactPersonUpdate(contactPerson)),
        propApiContactPersonDelete: (id)=> dispatch(apiContactPersonDelete(id)),
        propApiNoteDelete: (id,contact_person_id)=> dispatch(apiNoteDelete(id,contact_person_id))

    }
}




export class ContactPersonDisconnected extends Component{

    deleteContactPersonHandler = e => {
        e.preventDefault();
        this.props.propApiContactPersonDelete(this.props.contactPerson.id);
        this.setState({
            after_action:true
        })
    }

    constructor(props){
        super(props);

        this.noti = null;

        this.state = {
            edit:false,
            modalNoteOpen:false,
            modalNote:null,
            after_action: false
        }
    }

    componentDidMount() {

        this.props.propApiContactPersonGetOne(this.props.match.params.id);
        this.props.propApiCompanyGetAll();


        NotificationSystem.newInstance({}, n => this.noti = n);

    }

    componentDidUpdate(prevProps) {

        //console.log(this.state.after_action);
        if(!this.state.after_action){
            return;
        }

        if((this.props.contactPerson && this.props.contactPerson.notes || []).length <  (prevProps.contactPerson && prevProps.contactPerson.notes || []).length ){
            this.showNotification('Notatka została usunięta pomyślnie','primary');
            this.setState({
                after_action:false
            })
        }


        if(this.props.contactPersons === null || prevProps.contactPersons == null){
            return;
        }

        if(this.props.contactPersons .length > prevProps.contactPersons.length){
            this.showNotification('Osoba kontaktowa została dodana prawidłowo','primary');
            setTimeout(()=>{
                this.props.history.push(`/pages/company/${this.props.selectedCompany.id}`);
            },3000);
            this.setState({
                after_action:false
            })
        }else if(this.props.contactPersons .length < prevProps.contactPersons.length){
            this.showNotification('Osoba kontaktowa została usunięta','primary');
            setTimeout(()=>{
                this.props.history.push(`/pages/company/${this.props.selectedCompany.id}`);
            },3000);
            this.setState({
                after_action:false
            })

        }else if(this.props.success && !prevProps.success){
            this.showNotification('Dane zostały pomyślnie zapisane','primary');
            setTimeout(()=>{
                this.props.history.push(`/pages/company/${this.props.selectedCompany.id}`);
            },3000);
            this.setState({
                after_action:false
            })
        }
    }


    showNotification = (text,color) => {
        this.noti.notice({
            content: <FullWideNotification
                color={color}
                message={text}
            />,
            duration: 3,
            closable: true,
            style: { top: 0, left: 0,zIndex:1000,position:'fixed' },
            className: 'full',
        });
    }

    changeSelectedContactPersonField(e, fieldId)
    {
        e.preventDefault();
        let value = e.target.value;
        switch(fieldId) {

            case 'contact_person_name':
                this.props.propContactPersonSetOne({...this.props.contactPerson,name:value})
                break;

            case 'contact_person_surname':
                this.props.propContactPersonSetOne({...this.props.contactPerson,surname:value})
                break;

            case 'contact_person_jobFunction':
                this.props.propContactPersonSetOne({...this.props.contactPerson,jobFunction:value})
                break;

            case 'contact_person_email':
                this.props.propContactPersonSetOne({...this.props.contactPerson,email:value})
                break;

            case 'contact_person_phone':
                this.props.propContactPersonSetOne({...this.props.contactPerson,phone:value})
                break;

            case 'contact_person_company':
                if(this.props.contactPerson.company === null){
                    this.props.propContactPersonSetOne({...this.props.contactPerson,company_id:value, company: {id:value}})
                }else{
                    this.props.propContactPersonSetOne({...this.props.contactPerson,company_id:value, company: {...this.props.contactPerson.company,id:value}})
                }

                break;

            case 'contact_person_note':
                this.props.propContactPersonSetOne({...this.props.contactPerson,note:value})
                break;
        }
    }


    saveClickHandler(e) {
        e.preventDefault();
        if(this.props.contactPerson.company !== null){
            this.props.propApiContactPersonUpdate({...this.props.contactPerson,company_id:this.props.contactPerson.company.id})
        }else{
            this.props.propApiContactPersonUpdate({...this.props.contactPerson})
        }

        this.setState({
            after_action:true
        })
    }

    editClickHandler(e,tof) {
        e.preventDefault();
        this.setState({edit:tof});
    }

    render(){

        if(this.props.loading){
            return (
                <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }

        return (

            <Container className="dashboard">
                <Header subTitle="" title={`Dane osoby kontaktowej`} />

                <ContactPersonButtons
                    onDeleteContactPerson={this.deleteContactPersonHandler}
                    editMode={this.state.edit} onEditClick={(e,tof)=>this.editClickHandler(e,tof)}
                    onSaveClick={(e)=>this.saveClickHandler(e)}
                    contactPerson = {this.props.contactPerson}
                />
                <Row>
                    <Col xl={5}  lg={5} md={12} sm={12} xs={12}>
                        <ContactPersonEditCard
                            contact_person = {this.props.contactPerson}
                            errors = {this.props.errors}
                            companies = {this.props.companies}
                            onChangeField = {(e,fieldId)=>this.changeSelectedContactPersonField(e,fieldId)}
                            edit = {this.state.edit}
                            showNotification = {this.showNotification}
                        />
                    </Col>
                    <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                        <ContactPersonListCard
                            contactPerson={this.props.contactPerson}
                            showDetails = {this.showNoteDetailsHandler}
                        />
                    </Col>
                </Row>


                <Modal className="MMP-modal" isOpen={this.state.modalNoteOpen} toggle={this.toggleNoteModal}  backdrop={true} keyboard={true}>
                    <ModalHeader>
                        <span className={`sidebar__link-icon lnr lnr-layers`} /> Notatka nr {(this.state.modalNote && this.state.modalNote.id) || ''}
                    </ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardBody>
                                <Form>
                                    <FormGroup className="row">
                                        <Label className="col-lg-12 col-md-12">TREŚĆ NOTATKI</Label>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                            <CardBody>
                                { ReactHtmlParser((this.state.modalNote && this.state.modalNote.text) || '')}
                            </CardBody>
                            <CardBody>
                                <Form>
                                    <FormGroup className="row">
                                        <Label className="col-lg-12 col-md-12">DANE FIRMY</Label>
                                    </FormGroup>
                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Nazwa firmy</Label>
                                        <Col lg={9} md={9}>
                                            <Input  className="form-control" disabled={true} value={(this.props.contactPerson && this.props.contactPerson.company && this.props.contactPerson.company.name) || ''}/>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Osoba kontaktowa</Label>
                                        <Col lg={9} md={9}>
                                            <Input  className="form-control" disabled={true} value={(this.props.contactPerson && this.props.contactPerson.name+" "+this.props.contactPerson.surname ) || ''}/>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Data dodania</Label>
                                        <Col lg={9} md={9}>
                                            <Input  className="form-control" disabled={true} value={(this.state.modalNote && this.state.modalNote.createdAt) || ''}/>
                                        </Col>
                                    </FormGroup>


                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Załączniki</Label>
                                        <Col lg={9} md={9}>
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                            {
                                                ((this.state.modalNote && this.state.modalNote.attachments)  || [])
                                                    .map(a=>(
                                                        <div key={a.id}>
                                                            <span className="lnr lnr-paperclip"> <a onClick={(e)=>this.downloadFile(e, a.id,a.name)} href="#">{a.name}</a></span>
                                                        </div>

                                                    ))

                                            }


                                            </div>
                                        </Col>
                                    </FormGroup>

                                </Form>
                            </CardBody>
                            <Row className="align-items-center justify-content-between top_buttons_container">
                                <Col md={6} lg={6} className="buttons_sub_container"  >
                                    <div className="actions_button_container red"><a href="#" onClick={this.deleteNote} >Usuń</a></div>
                                </Col>
                            </Row>
                        </Card>
                    </ModalBody>
                </Modal>



            </Container>
        )

    }

    componentWillUnmount() {
        this.noti.destroy();
    }

    showNoteDetailsHandler = (e,note) =>{
        e.preventDefault();
        this.setState({modalNote:note})
        this.toggleNoteModal();
    }

    toggleNoteModal = ()=>{
        this.setState(prevState=>({modalNoteOpen:!prevState.modalNoteOpen}))
    }
    deleteNote = (e) => {
        e.preventDefault()
        this.toggleNoteModal();
        this.props.propApiNoteDelete(this.state.modalNote.id,((this.props.contactPerson && this.props.contactPerson.id) || null));

        this.setState({
            after_action:true
        })

    }


    downloadFile = (e,id,name) => {
        downloadFile(e,id,name);
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ContactPersonDisconnected))
