import {URL} from "./constants";
import {setLastNoteId} from "./noteActions";


export const TASK_ACTIONS = {

    SET_ALL_TASKS : 'SET_ALL_TASKS',
    SET_ONE_TASK : 'SET_ONE_TASK',
    SET_TASKS_LOADING: 'SET_TASKS_LOADING',
    SET_FILES_LOADING: 'SET_FILES_LOADING',
    SET_LAST_TASK_ID: 'SET_LAST_TASK_ID',
    SET_LAST_CHANGE_ID: 'SET_LAST_CHANGE_ID',
    SET_TASK_STATUSES: 'SET_TASK_STATUSES',
    SET_TASK_PRIORITIES: 'SET_TASK_PRIORITIES',
    SET_TASK_OWNERS: 'SET_TASK_OWNERS',
    SET_LAST_FILE_UPLOADED: 'SET_LAST_FILE_UPLOADED',


}


export const  tasksSetAll = (tasks) => ({
    type: TASK_ACTIONS.SET_ALL_TASKS,
    tasks
})

export const tasksSetOne = (task) => ({

    type: TASK_ACTIONS.SET_ONE_TASK,
    task
})
export const tasksSetLoading = (tof) => ({
    type: TASK_ACTIONS.SET_TASKS_LOADING,
    tof
})
export const tasksSetLoadingFiles = (tof) => ({
    type: TASK_ACTIONS.SET_FILES_LOADING,
    tof
})

export const setLastTaskId = id => ({
    type: TASK_ACTIONS.SET_LAST_TASK_ID,
    id
});

export const setAllStatuses = statuses => ({
    type: TASK_ACTIONS.SET_TASK_STATUSES,
    statuses
});

export const setAllPriorities = priorities => ({
    type: TASK_ACTIONS.SET_TASK_PRIORITIES,
    priorities
});

export const setAllOwners = owners => ({
    type: TASK_ACTIONS.SET_TASK_OWNERS,
    owners
});

export const setLastChangeId = () => ({
    type: TASK_ACTIONS.SET_LAST_CHANGE_ID
});

export const setLastFileUploaded = (tof) => ({
    type: TASK_ACTIONS.SET_LAST_FILE_UPLOADED,
    tof
});



export const apiTasksCreateNew = (task) => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...task})


        }).then( response => {

            dispatch(tasksSetLoading(false));

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{


                    dispatch(apiTasksGetAll());
                    dispatch(setLastTaskId(parseInt(data.data)))
                    dispatch(setLastChangeId())
                   // alert('Note created successfully');


                }).catch(error=>{


                    alert('error');
                })

            }else{


                alert("error");
            }
        })
    }
}


export const apiFileUpload = (binaryStr,filename,size,taskId, last = false)=> {

    return dispatch => {

        dispatch(tasksSetLoadingFiles(true))
        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/file/upload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body:encodeURIComponent("filename")+"="+encodeURIComponent(filename)
                +"&"+encodeURIComponent("binaryStr")+"="+encodeURIComponent(binaryStr)
                +"&"+encodeURIComponent("size")+"="+encodeURIComponent(size)
                +"&"+encodeURIComponent("taskId")+"="+encodeURIComponent(taskId)

        }).then(response=>{

            if(response.status === 200 &&  response.headers.get("content-type") === 'application/json'){



                if(last){
                    dispatch(tasksSetLoadingFiles(false))
                    dispatch(tasksSetLoading(false))
                   // alert('all files have been uploaded successfully')
                    dispatch(apiTasksGetAll());
                    dispatch(setLastFileUploaded(true));
                }

            }
        }).catch(error=>{

            dispatch(tasksSetLoadingFiles(false))
            dispatch(tasksSetLoading(false))
            alert('error');

        })
    }
}



export const apiTasksUpdate = (task,silent = false) => {

    return dispatch => {

        if(!silent){
            dispatch(tasksSetLoading(true))
        }


        fetch(`${URL}v1/task`,{

            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...task})


        }).then( response => {

            if(!silent) {
                dispatch(tasksSetLoading(false));
            }


            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{


                    dispatch(apiTasksGetAll());
                    dispatch(setLastTaskId(parseInt(data.data)));
                    dispatch(setLastChangeId())
                    if(!silent) {
                      //  alert('task updated successfully');
                    }


                }).catch(error=>{


                    alert('error');
                })

            }else{


                alert("error");
            }
        })
    }
}


export const apiTasksGetAll = () => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task/all`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(tasksSetLoading(false))

            if (response.status === 200 && response.headers.get("content-type") === 'application/json') {

                response.json().then(data => {


                    dispatch(tasksSetAll(data.data))

                }).catch(error => {
                    alert('error');
                })

            }else if(response.status === 401){
                //nothing intercept to login page
            }else{

                alert("error");
            }
        })
    }
}


export const apiTaskStatusesGetAll = () => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task/statuses`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(tasksSetLoading(false))

            if (response.status === 200 && response.headers.get("content-type") === 'application/json') {

                response.json().then(data => {

                    dispatch(setAllStatuses(data.data))

                }).catch(error => {
                    alert('error');
                })

            }else if(response.status === 401){
                //nothing intercept to login page
            }else{

                alert("error");
            }
        })
    }
}


export const apiTaskPrioritiesGetAll = () => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task/priorities`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(tasksSetLoading(false))

            if (response.status === 200 && response.headers.get("content-type") === 'application/json') {

                response.json().then(data => {

                    dispatch(setAllPriorities(data.data))

                }).catch(error => {
                    alert('error');
                })

            }else if(response.status === 401){
                //nothing intercept to login page
            }else{

                alert("error");
            }
        })
    }
}


export const apiTaskOwnersGetAll = () => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task/owners`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(tasksSetLoading(false))

            if (response.status === 200 && response.headers.get("content-type") === 'application/json') {

                response.json().then(data => {

                    dispatch(setAllOwners(data.data))

                }).catch(error => {
                    alert('error');
                })

            }else if(response.status === 401){
                //nothing intercept to login page
            }else{

                alert("error");
            }
        })
    }
}




export const apiTasksGetOne = (id) => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task/${id}`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(tasksSetLoading(false))

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(tasksSetOne(data.data))


                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}


export const apiTasksDelete = (id) => {

    return dispatch => {

        dispatch(tasksSetLoading(true))

        fetch(`${URL}v1/task/${id}`,{

            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                dispatch(apiTasksGetAll());
                dispatch(tasksSetLoading(true))
                dispatch(setLastChangeId())


          //      alert('task has been removed');

            }else{

                alert("error");
            }
        })
    }
}




