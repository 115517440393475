import {URL} from "../../redux/actions/constants";
import {saveAs} from "file-saver";

export const downloadFile = (e,id,name) =>{
    e.preventDefault()

    fetch(`${URL}v1/file/download/${id}`, {
        method: 'GET',

    }).then( res => res.blob() )
        .then( blob => {
            // let file = window.URL.createObjectURL(blob);
            saveAs(blob, name);
            //window.location.assign(file);
        });
};

export const arrayBufferToBase64 = ( buffer ) => {
    let binary = '';
    let bytes = new Uint8Array( buffer );
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}
