import React, { Component, Fragment } from 'react';

import {connect} from "react-redux";

import {apiTasksGetAll, apiTaskStatusesGetAll, apiTasksUpdate} from "../../redux/actions/taskActions";

import {Col, Container, Row} from "reactstrap";
import Header from "../../shared/header";

import Buttons from "./Components/Buttons";
import TableCard from "./Components/TableCard";


const mapStateToProps = (state) => {

    return {
        statuses: state.tasks.taskStatuses,
        tasks: state.tasks.tasks,
        loading_tasks: state.tasks.loading_tasks
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        propApiTaskStatusesGetAll: () => dispatch(apiTaskStatusesGetAll()),
        propApiTasksGetAll: () => dispatch(apiTasksGetAll()),
        propApiTasksUpdate: (task,silent) => dispatch(apiTasksUpdate(task,silent)),

    }
}


class TasksTable extends Component{



    constructor(props){
        super(props);

        this.state = {

        }

    }

    componentDidMount() {

        this.props.propApiTaskStatusesGetAll();
        this.props.propApiTasksGetAll();
    }



    render(){



        return(

            <>

                {

                    this.props.loading_tasks?
                        (
                            <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                    </svg>
                                </div>
                            </div>
                        ):
                        (
                            <Container className="dashboard">
                                <Header subTitle={`Lista wszystkich zadań znajdujących się w ekosystemie MuchMore`}  title={`Zadania`} />
                                <Buttons/>
                                <Row>
                                    <TableCard tasks={this.props.tasks}/>
                                </Row>
                            </Container>
                        )

                }

                </>

        )
    }

}

export default connect(mapStateToProps,mapDispatchToProps)(TasksTable)
