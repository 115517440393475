import React from 'react';
import { Card, CardBody, Col ,Table } from 'reactstrap';
import {Link} from "react-router-dom";
import {stripHtml} from '../../../shared/functions/stripHtml'

const ContactPersonNotesTable = ({contactPerson,showDetails}) => (
    <Col md={12}>
        <Card>
            <CardBody>
                <Table responsive={true} striped={false}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Data</th>
                        <th>Autor</th>
                        <th>Treść</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        ((contactPerson && contactPerson.notes) || []).map(n=>{

                            const text = stripHtml(n.text);

                            return (
                            <tr key={n.id}>
                                <td>{n.id}</td>
                                <td>{n.createdAt}</td>
                                <td>Mateusz Lewandowski</td>
                                <td>{text.length > 200? `${text.substring(0,200)}...` :text}</td>
                                <td className="actions_button_container"><a href="#" onClick={(e)=>showDetails(e,n)}>Więcej</a></td>
                            </tr>
                        )})
                    }

                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </Col>
);

export default ContactPersonNotesTable;
