import {URL} from './constants';
import {
    apiCompanyGetAll, COMPANY_ACTIONS,
    companyAddNew, companyRemove,
    companySetAll,
    companySetErrors,
    companySetLoading,
    companySetOne,
    companySetSuccess, companyUpdateOne
} from "./companyActions";

export const CONTACT_PERSON_ACTIONS = {

    CONTACT_PERSON_REMOVE  :   'CONTACT_PERSON_REMOVE',
    CONTACT_PERSON_SET_ONE :   'CONTACT_PERSON_SET_ONE',
    CONTACT_PERSON_SET_ALL :   'CONTACT_PERSON_SET_ALL',
    CONTACT_PERSON_SET_ERRORS: 'CONTACT_PERSON_SET_ERRORS',
    CONTACT_PERSON_UPDATE_ONE: 'CONTACT_PERSON_UPDATE_ONE',
    CONTACT_PERSON_ADD_NEW : 'CONTACT_PERSON_ADD_NEW',
    CONTACT_PERSON_SET_LOADING: 'CONTACT_PERSON_SET_LOADING',
    CONTACT_PERSON_SET_SUCCESS: 'CONTACT_PERSON_SET_SUCCESS'
};

export const contactPersonSetSuccess = tof => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_SUCCESS,
    tof,
});

export const contactPersonSetLoading = tof => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_LOADING,
    tof,
});

export const contactPersonUpdateOne = contactPerson => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_UPDATE_ONE,
    contactPerson,
});

export const contactPersonSetOne = contactPerson => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_ONE,
    contactPerson,
});

export const contactPersonAddNew = contactPerson => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_ADD_NEW,
    contactPerson,
});

export const contactPersonSetAll = contactPersons => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_ALL,
    contactPersons,
});

export const contactPersonSetErrors = errors => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_ERRORS,
    errors,
});

export const contactPersonRemove = id => ({
    type: CONTACT_PERSON_ACTIONS.CONTACT_PERSON_REMOVE,
    id,
});

export const apiContactPersonGetAll = (company_id = null) => {

    let uri = `${URL}v1/contact-person/company/${company_id}`;

    if(company_id === null){
        uri = `${URL}v1/contact-persons`;
    }

    return dispatch => {

        dispatch(contactPersonSetLoading(true))

        fetch(uri,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {


            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(contactPersonSetAll(data.data))
                    dispatch(contactPersonSetLoading(false))


                }).catch(error=>{alert('error');})

            }else{
                alert("error");
                dispatch(contactPersonSetLoading(true))
            }
        })
    }
}


export const apiContactPersonGetOne = (id) => {

    return dispatch => {

        if(id === null || id === undefined || isNaN(id)){

            dispatch(contactPersonSetOne(null))
            return false;
        }

        dispatch(contactPersonSetLoading(true))

        fetch(`${URL}v1/contact-person/${id}`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(contactPersonSetOne(data.data))
                    dispatch(contactPersonSetLoading(false))


                }).catch(error=>{alert('error');})

            }else{

                dispatch(contactPersonSetLoading(false))

                alert("error");
            }
        })
    }
}


export const apiContactPersonCreateNew = (contactPerson) => {

    return dispatch => {

        dispatch(contactPersonSetErrors([]));
        dispatch(contactPersonSetLoading(true))
        dispatch(contactPersonSetSuccess(false))


        fetch(`${URL}v1/contact-person`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactPerson)

        }).then( response => {

            dispatch(contactPersonSetLoading(false))

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(contactPersonAddNew(contactPerson))

                    dispatch(contactPersonSetSuccess(true))
                    //alert('contact person created successfully');



                }).catch(error=>{alert('error');})

            }else if(response.status === 400){

                response.json().then(data=>{

                    dispatch(contactPersonSetErrors(data.errors));


                }).catch(error=>{alert('error');})

            }else if(response.status === 404){

                response.json().then(data=>{

                    dispatch(contactPersonSetErrors([{invalid_property:'global',message:data.data}]));


                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}

export const apiContactPersonUpdate = (contactPerson) => {

    return dispatch => {

        dispatch(contactPersonSetErrors([]));
        dispatch(contactPersonSetLoading(true));
        dispatch(contactPersonSetSuccess(false))

        fetch(`${URL}v1/contact-person`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactPerson)

        }).then( response => {

            dispatch(contactPersonSetLoading(false))

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                dispatch(contactPersonUpdateOne(contactPerson));

                dispatch(contactPersonSetSuccess(true))
              //  alert('contact person updated successfully');

            }else if(response.status === 400){

                response.json().then(data=>{

                    dispatch(contactPersonSetErrors(data.errors));

                }).catch(error=>{alert('error');})

            }else if(response.status === 404){

                response.json().then(data=>{

                    dispatch(contactPersonSetErrors([{invalid_property:'global',message:data.data}]));


                }).catch(error=>{alert('error');})

            }else{
                dispatch(contactPersonSetLoading(false))
                alert("error");
            }
        })
    }
}


export const apiContactPersonDelete = (id) => {

    return dispatch => {

        dispatch(companySetLoading(true))
        dispatch(contactPersonSetLoading(true))

        fetch(`${URL}v1/contact-person/${id}`,{

            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                dispatch(companyRemove(id));
                dispatch(contactPersonSetLoading(false))
                dispatch(apiContactPersonGetAll())


                //alert('contact person has been removed');

            }else{
                dispatch(contactPersonSetLoading(false))
                alert("error");
            }
        })
    }
}







