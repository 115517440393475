import {Component} from "react";

import Card from "react-trello/dist/components/Card"
import  _propTypes  from 'prop-types';

class CustomCard extends Card {

}

CustomCard.propTypes = {
    showDeleteButton: _propTypes.bool,
    onDelete: _propTypes.func,
    onClick: _propTypes.func,
    style: _propTypes.object,
    tagStyle: _propTypes.object,
    className: _propTypes.string,
    id: _propTypes.string.isRequired,
    title: _propTypes.string.isRequired,
    label: _propTypes.string,
    description: _propTypes.object,
    tags: _propTypes.array
};

export default CustomCard;
