import {CLIENT_TYPE_ACTIONS} from "../actions/clientTypeActions";


const initialState = {
    clientTypes:[]
}


export default function(state = initialState, action){
    switch(action.type){

        case CLIENT_TYPE_ACTIONS.SET_ALL_CLIENT_TYPES:

            return {
                ...state,
                clientTypes: action.data
            }

        default:
            return state;
    }

}