import React, { Component, Fragment } from 'react';
import {Card, CardBody, Col, Form, FormGroup, Input, Label} from "reactstrap";

export default class ContactPersonEditCard extends Component {


    constructor(props){

        super(props);
        this.state = {
            errors_list:{
                contact_person_name: {is_invalid:false, message:""},
                contact_person_surname: {is_invalid:false, message:""},
                contact_person_phone: {is_invalid:false, message:""},
                contact_person_email: {is_invalid:false, message:""},
                contact_person_jobFunction: {is_invalid:false, message:""},
                contact_person_note: {is_invalid:false, message:""},
            }
        }

    }


    componentDidUpdate(prevProps) {

        if(((prevProps.errors &&  prevProps.errors.length) || 0) === ((this.props.errors &&  this.props.errors.length) || 0)){
           return;
        }


        if(((this.props.errors &&  this.props.errors.length) || 0) > 0){

            this.props.errors.forEach(e => {
                let text = e.message;
                this.props.showNotification(text,'danger');

            })
        }

        this.setState(prevState => {
            return {
                errors_list:{
                    ...prevState.errors_list,

                    contact_person_name:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "name")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "name")) > -1 ? this.props.errors.find(e => (e.invalid_property === "name")).message || "" : ""
                    },

                    contact_person_surname:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "surname")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "surname")) > -1 ? this.props.errors.find(e => (e.invalid_property === "surname")).message || "" : ""
                    },

                    contact_person_jobFunction:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "jobFunction")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "jobFunction")) > -1 ? this.props.errors.find(e => (e.invalid_property === "jobFunction")).message || "" : ""
                    },

                    contact_person_email:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "email")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "email")) > -1 ? this.props.errors.find(e => (e.invalid_property === "email")).message || "" : ""
                    },

                    contact_person_phone:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "phone")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "phone")) > -1 ? this.props.errors.find(e => (e.invalid_property === "phone")).message || "" : ""
                    },

                    contact_person_note:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "note")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "note")) > -1 ? this.props.errors.find(e => (e.invalid_property === "note")).message || "" : ""
                    },


                }
            }
        })
    }



    render() {
        return (

                <Card className="contact-person-card">
                    <CardBody>

                        <Form>
                            <FormGroup className="row">
                                <Label className="col-lg-12 col-md-12">DANE OSOBY KONTAKTOWEJ</Label>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label for="contact_person_name" className="col-lg-3 col-md-3">Imię</Label>
                                <Col lg={9} md={9}>
                                    <Input id="contact_person_name" className={`form-control ${this.isInvalid('contact_person_name')}`} onChange={(e)=>this.props.onChangeField(e,'contact_person_name')} disabled={!this.props.edit} value={(this.props.contact_person && this.props.contact_person.name) || ''}/>
                                    <div className="invalid-feedback">{this.getError('contact_person_name')}</div>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label for="contact_person_surname" className="col-lg-3 col-md-3">Nazwisko</Label>
                                <Col lg={9} md={9}>
                                    <Input id="contact_person_surname" className={`form-control ${this.isInvalid('contact_person_surname')}`} onChange={(e)=>this.props.onChangeField(e,'contact_person_surname')} disabled={!this.props.edit} value={(this.props.contact_person && this.props.contact_person.surname) || ''}/>
                                    <div className="invalid-feedback">{this.getError('contact_person_name')}</div>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label for="contact_person_jobFunction" className="col-lg-3 col-md-3">Stanowisko</Label>
                                <Col lg={9} md={9}>
                                    <Input id="contact_person_jobFunction" className={`form-control ${this.isInvalid('contact_person_jobFunction')}`} onChange={(e)=>this.props.onChangeField(e,'contact_person_jobFunction')} disabled={!this.props.edit} value={(this.props.contact_person && this.props.contact_person.jobFunction) || ''}/>
                                    <div className="invalid-feedback">{this.getError('contact_person_jobFunction')}</div>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label for="contact_person_email" className="col-lg-3 col-md-3">Email</Label>
                                <Col lg={9} md={9}>
                                    <Input id="contact_person_email" className={`form-control ${this.isInvalid('contact_person_email')}`} onChange={(e)=>this.props.onChangeField(e,'contact_person_email')} disabled={!this.props.edit} value={(this.props.contact_person && this.props.contact_person.email) || ''}/>
                                    <div className="invalid-feedback">{this.getError('contact_person_email')}</div>
                                </Col>
                            </FormGroup>


                            <FormGroup className="row align-items-center">
                                <Label for="contact_person_phone" className="col-lg-3 col-md-3">Telefon</Label>
                                <Col lg={9} md={9}>
                                    <Input id="contact_person_phone" className={`form-control ${this.isInvalid('contact_person_phone')}`} onChange={(e)=>this.props.onChangeField(e,'contact_person_phone')} disabled={!this.props.edit} value={(this.props.contact_person && this.props.contact_person.phone) || ''}/>
                                    <div className="invalid-feedback">{this.getError('contact_person_phone')}</div>
                                </Col>
                            </FormGroup>

                            <FormGroup className="row align-items-center">
                                <Label for="contact_person_company" className="col-lg-3 col-md-3">Firma</Label>
                                <Col lg={9} md={9}>
                                    <select onChange={(e)=>this.props.onChangeField(e,'contact_person_company')} value={(this.props.contact_person && this.props.contact_person.company && this.props.contact_person.company.id) ||''} id="contact_person_company" className="form-control" disabled={!this.props.edit}>
                                        <option>Choose...</option>
                                        {
                                            (this.props.companies || [])
                                                .map((ct)=>(
                                                    <option key={ct.id} value={ct.id}>{ct.name}</option>
                                                ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>

                        </Form>
                    </CardBody>

                    <CardBody>
                        <Form>
                            <FormGroup className="row">
                                <Label className="col-lg-12 col-md-12">KOMENTARZ</Label>
                            </FormGroup>
                            <FormGroup className="row">

                                <Col lg={12} md={12}>
                                    <Input className={`form-control ${this.isInvalid('contact_person_note')}`}   onChange={(e)=>this.props.onChangeField(e,'contact_person_note')} type="textarea" value={(this.props.contact_person && this.props.contact_person.note) ||''}  id="contact_person_note"  rows={10} />
                                    <div className="invalid-feedback">{this.getError('contact_person_note')}</div>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>






        )
    }

    isInvalid = field => {
        return  ((this.state.errors_list[field] && this.state.errors_list[field] && this.state.errors_list[field].is_invalid) ? 'is-invalid':'' ) || ''
    }

    getError = field => {
        return  (this.state.errors_list[field] && this.state.errors_list[field] && this.state.errors_list[field].message) || ""
    }
}