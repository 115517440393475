import {NOTE_ACTIONS} from "../actions/noteActions";


const initialState = {
    lastNoteId: -1,
    loading_files:false,
    loading_notes:false,
    upload_success:false,
    notes:[],


};

export default function (state = initialState, action) {
    switch (action.type) {

        case NOTE_ACTIONS.SET_NOTES_LOADING:
            return { ...state, loading_notes: action.tof};

        case NOTE_ACTIONS.SET_FILES_LOADING:
            return { ...state, loading_files: action.tof};

        case NOTE_ACTIONS.SET_LAST_NOTE_ID:
            return { ...state, lastNoteId: action.id };

        case NOTE_ACTIONS.SET_ALL_FILES_UPLOADED_FLAG:
            return { ...state, upload_success: action.tof };

        case NOTE_ACTIONS.NOTES_SET_ALL:
            return { ...state, notes: action.notes };

        default:
            return state;
    }
}
