import {CONTACT_PERSON_ACTIONS} from "../actions/contactPersonActions";
import {COMPANY_ACTIONS} from "../actions/companyActions";

const initialState = {

    selectedContactPerson: null,
    contactPersons: [],
    errors:[],
    loading:false,
    success:false
}

export default function(state = initialState, action){
    switch(action.type){

        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_SUCCESS:

            return {
                ...state,
                success: action.tof
            }

        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_LOADING:

            return {
                ...state,
                loading: action.tof
            }



        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_ERRORS:

            return {...state, errors: action.errors}

        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_ADD_NEW:

            return {...state,contactPersons:[...state.contactPersons,action.contactPerson]};

        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_ONE:

            return {...state,selectedContactPerson: action.contactPerson};

        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_REMOVE:

            let newCP =  state.contactPersons.filter(c=> parseInt(c.id) !== parseInt(action.id));

            return {...state, contactPersons: newCP }

        case CONTACT_PERSON_ACTIONS.CONTACT_PERSON_SET_ALL:

            return {...state,contactPersons: action.contactPersons}

        default:
            return state
    }
}