import React from 'react';
import ReactHtmlParser  from 'react-html-parser';

const TaskCardDetails = (props) => {

    const splited_date = (props.deadline || '').substring(5).split('-');


    return(



         <div className="task_card_details">
             <div>
                 {ReactHtmlParser(props.description)}
             </div>
             <div className="task_card_details_flex">
                 <div className="task_card_details_flex_time">
                     <span className="lnr lnr-clock"></span>
                     <div>
                         <span>{`${splited_date[1] || ''}/${splited_date[0] || ''}`}</span>
                         <span>({props.days} dni</span>
                     </div>
                 </div>


                 <div className="task_card_details_flex_time">
                     <span className="lnr lnr-database"></span>
                     <div>{(props.priority || {name:'brak'}).name}</div>
                 </div>
             </div>
             <div className="task_card_details_flex">
                 <div className="task_card_details_flex_id">{props.company} - {props.task_id}</div>
                 <div className="task_card_details_flex_owner">{props.owner}</div>
             </div>
         </div>
    )
}

export default TaskCardDetails;
