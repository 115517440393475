import {URL} from "./constants";

export const GROUP_ACTIONS = {

    SET_ALL_GROUPS : 'SET_ALL_GROUPS',
}

export const groupsSetAll = (data)=>({
    type:GROUP_ACTIONS.SET_ALL_GROUPS,
    data
})

export const apiGroupsGetAll = () => {

    return dispatch => {

        fetch(`${URL}v1/groups`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(groupsSetAll(data.data))

                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}