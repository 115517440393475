import {STATUS_ACTIONS} from "../actions/statusActions";


const initialState = {
    statuses:[]
}


export default function(state = initialState, action){
    switch(action.type){

        case STATUS_ACTIONS.SET_ALL_STATUSES:

            return {
                ...state,
                statuses: action.data
            }

        default:
            return state;
    }

}