import React, { Component, Fragment } from 'react';
import {Col, Container, Row} from "reactstrap";

import ContactPersonCard from "./components/ContactPersonCard";
import Header from "../../shared/header";
import { connect } from 'react-redux'
import ButtonsCompanies from "./components/Buttons";
import {apiContactPersonGetAll} from "../../redux/actions/contactPersonActions";



const mapStateToProps = (state) => {

    return {
        contactPersons: state.contactPersons.contactPersons,
        loading:state.contactPersons.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        propApiContactPersonGetAll: ()=> dispatch(apiContactPersonGetAll())
    }
}

class ContactPersons extends Component{

    componentDidMount() {

        this.props.propApiContactPersonGetAll();
    }

    render() {

        if(this.props.loading){
            return (
                <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }

        return(

            <Container className="dashboard">
                <Header subTitle={"Lista osób kontaktowych przypisanych do firm."} title={"Osoby kontaktowe"} />
               
                <Row>
                    <ContactPersonCard contactPersons={this.props.contactPersons}/>
                </Row>
            </Container>

        )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(ContactPersons)