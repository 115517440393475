import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, error, loading } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Użytkownik</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder="Podaj adres email"

            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Hasło</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Hasło"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          {/*<div className="account__forgot-password">*/}
          {/*  <a href="/">Forgot a password?</a>*/}
          {/*</div>*/}
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            {/*<Field*/}
            {/*  name="remember_me"*/}
            {/*  component={renderCheckBoxField}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
          </div>
        </div>
        <div className="login_button_container">

          {

            loading?
            (
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
            ):
            (
                <button className="btn btn-primary account__btn account__btn--small"  type="submit">Zaloguj się</button>
            )


          }


        </div>



      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
