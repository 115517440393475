import React, { Component, Fragment } from 'react';

import Board from 'react-trello';
import {connect} from "react-redux";
import {NotesDisconnected} from "../Notes";
import {
    apiTaskOwnersGetAll, apiTaskPrioritiesGetAll,
    apiTasksCreateNew, apiTasksDelete,
    apiTasksGetAll,
    apiTaskStatusesGetAll,
    apiTasksUpdate, tasksSetLoading,
    tasksSetOne,
    apiFileUpload, setLastFileUploaded
} from "../../redux/actions/taskActions";

import {Col, Container, Row} from "reactstrap";
import Header from "../../shared/header";

import Buttons from "./Components/Buttons";
import TaskCardDetails from "./Components/TaskCardDetails";
import CustomCard from "./Components/CustomCard";

import classNames from "classnames";
import TaskEditCard from "./Components/TaskEditCard";
import {apiCompanyGetAll} from "../../redux/actions/companyActions";
import {apiContactPersonGetAll} from "../../redux/actions/contactPersonActions";
import {URL} from "../../redux/actions/constants";

import {downloadFile,arrayBufferToBase64} from '../../shared/functions/fileFunctions';

import NotificationSystem from "rc-notification/es";
import {FullWideNotification} from "../../shared/components/Notification";



const mapStateToProps = (state) => {

    return {
        statuses: state.tasks.taskStatuses,
        lastChangeId: state.tasks.lastChangeId,
        lastTaskId: state.tasks.lastTaskId,
        priorities: state.tasks.priorities,
        owners: state.tasks.owners,
        tasks: state.tasks.tasks,
        loading_tasks: state.tasks.loading_tasks,
        loading_files: state.tasks.loading_files,
        selectedTask:state.tasks.selectedTask,
        fileJustUploaded:state.tasks.fileJustUploaded,

        company_loading: state.company.loading,
        contact_person_loading:  state.contactPersons.loading,

        companies: state.company.companies,
        contactPersons: state.contactPersons.contactPersons
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
            propApiTaskStatusesGetAll: () => dispatch(apiTaskStatusesGetAll()),
            propApiTaskOwnersGetAll: () => dispatch(apiTaskOwnersGetAll()),
            propApiTaskPrioritiesGetAll: () => dispatch(apiTaskPrioritiesGetAll()),
            propTasksSetLoading: (tof) => dispatch(tasksSetLoading(tof)),

            propApiTasksGetAll: () => dispatch(apiTasksGetAll()),
            propApiTasksUpdate: (task,silent) => dispatch(apiTasksUpdate(task,silent)),
            propApiTasksCreateNew: (task) => dispatch(apiTasksCreateNew(task)),
            propApiTasksDelete: (id) => dispatch(apiTasksDelete(id)),
            propTasksSetOne: (task) => dispatch(tasksSetOne(task)),

            propApiContactPersonGetAll: () => dispatch(apiContactPersonGetAll()),
            propApiCompanyGetAll: () => dispatch(apiCompanyGetAll()),
            propApiFileUpload: (binaryStr,filename,size,taskId,last) => dispatch(apiFileUpload(binaryStr,filename,size,taskId,last)),
            propSetLastFileUploaded: (tof) => dispatch(setLastFileUploaded(tof))


    }
}


class Tasks extends Component{

    changeHandler = (data) => {
       // console.log(data);
    };

    dragHandler = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {

        this.props.propTasksSetLoading(true);

        const task = (this.props.tasks || []).find(t=>{
          return   parseInt(t.id) === parseInt(cardId)
        })

        if(task !== undefined){

            this.props. propApiTasksUpdate({...task,status_id:targetLaneId },true)
        }
        if(this.props.selectedTask !== null){

            const status = (this.props.statuses || []).find( s => (parseInt(s.id) === parseInt(targetLaneId)))

            this.props.propTasksSetOne({...this.props.selectedTask, status })
        }
    };
    onTaskClickHandler = (cardId, metadata, laneId) => {
            this.setState(prevState=>{
                return {
                    opened: !prevState.opened
                }
            })
            const selectedTask = (this.props.tasks || []).find(t=> (parseInt(cardId) === parseInt(t.id)) )
            this.props.propTasksSetOne(selectedTask);
    };
    addHandler = (e) => {
        e.preventDefault()
        this.setState({
            opened:true
        })

        this.props.propTasksSetOne({
            status_id: (this.props.statuses && this.props.statuses[0] && this.props.statuses[0].id) || null,
            status: (this.props.statuses && this.props.statuses[0]) || null,
            owner_id: 1,
            company_id: null,
            contact_person_id:null,

            name: '',
            description: '',
            deadline: null,
            estimatedTime: 1,
            realTime: 0,
            rate: 0


        });

    };
    onSaveHandler = (e) => {

        e.preventDefault();

        let last_operation = 1;

        if(this.props.selectedTask.id === undefined || this.props.selectedTask === null){
            this.props.propApiTasksCreateNew({...this.props.selectedTask})
        }else{
            last_operation = 2;
            this.props.propApiTasksUpdate({...this.props.selectedTask},false);
        }
        this.setState({opened:false,last_operation:last_operation});
    };

    onRateChangeHandler = e => {
        const ec = {...e};
        this.props.propTasksSetOne({...this.props.selectedTask, rate:ec.target.value})
    };

    onContactPersonChangeHandler = contactPerson => {

        const contactPersonFound = this.props.contactPersons.find( cp => (parseInt(cp.id) === parseInt(contactPerson.value)))
        this.props.propTasksSetOne({...this.props.selectedTask, contact_person_id:contactPerson.value, contactPerson:contactPersonFound})
    };


    onCompanyChangeHandler = company => {

        const companyFound = this.props.companies.find( cp => (parseInt(cp.id) === parseInt(company.value)))
        this.props.propTasksSetOne({...this.props.selectedTask, company_id:company.value, company:companyFound})
    };


    onRealTimeChangeHandler = e => {
        const ec = {...e};
        this.props.propTasksSetOne({...this.props.selectedTask, realTime:ec.target.value})
    };


    onEstimatedTimeChangeHandler = e => {
        const ec = {...e};
        this.props.propTasksSetOne({...this.props.selectedTask, estimatedTime:ec.target.value})
    };

    onTaskOwnerChangeHandler = (owner) => {
        const ownerFound = this.props.owners.find( cp => (parseInt(cp.id) === parseInt(owner.value)))
        this.props.propTasksSetOne({...this.props.selectedTask, owner_id:owner.value, owner:ownerFound})
    };

    onDeadlineChangeHandler = e => {
        const ec = {...e};
        this.props.propTasksSetOne({...this.props.selectedTask, deadline:ec.target.value})
    };


    onDropFileHandler = (files) => {
        //  console.log(files);
        this.setState(prevState=>({
            files:[...prevState.files,...files]
        }))
    }

    onFileRemoveHandler = (f,e) => {
        this.setState(prevState => {

            let filteredFiles = prevState.files.filter(fi => {
                return fi.name !== f.name
            })

            return {
                files: filteredFiles
            }
        })
    };


    uploadFiles = noteId => {
        let len = this.state.files.length;
        let last = false;

        for(let i =0; i < len; i++) {

            const reader = new FileReader()
            reader.onabort = () => alert('aborted')
            reader.onerror = () => alert('error')
            reader.onload = () => {
                const binaryStr = reader.result;
                this.props.propApiFileUpload(
                    arrayBufferToBase64(binaryStr),
                    this.state.files[i].name,
                    this.state.files[i].size,
                    noteId,
                    i === len -1
                )
                if(i === len -1){
                    this.setState({files:[]})
                }

            }
            reader.readAsArrayBuffer(this.state.files[i])

        }
    }
    downloadFileHandler = (e,id,name) =>{
        downloadFile(e,id,name);
    };



    onDescriptionChangeHandler = data => {
        //const ec = {...e};
        this.props.propTasksSetOne({...this.props.selectedTask, description:data})
    };

    onNameChangeHandler = (e) => {
        const ec = {...e};

        this.props.propTasksSetOne({...this.props.selectedTask, name:ec.target.value})

    }
    onDeleteHandler = (e) => {
        e.preventDefault();
        this.props.propApiTasksDelete(this.props.selectedTask.id);
        this.setState({
            opened:false,
            last_operation:3
        })
    };

    onStatusChangeHandler = (status) => {
        const statusFound = this.props.statuses.find( cp => (parseInt(cp.id) === parseInt(status.value)))
        this.props.propTasksSetOne({...this.props.selectedTask, status_id:status.value, status:statusFound})
    };

    onPriorityChangeHandler = (priority) => {
        const priorityFound = this.props.priorities.find( cp => (parseInt(cp.id) === parseInt(priority.value)))
        this.props.propTasksSetOne({...this.props.selectedTask, priority_id:priority.value, priority:priorityFound})
    };


    constructor(props){
        super(props);

        this.noti = null;
        this.state = {
            opened:false,
            files:[],
            lastOperation:0
        }

    }

    showNotification = (text,color) => {
        this.noti.notice({
            content: <FullWideNotification
                color={color}
                message={text}
            />,
            duration: 3,
            closable: true,
            style: { top: 0, left: 0,zIndex:1000,position:'fixed' },
            className: 'full',
        });
    }

    componentWillUnmount() {
        this.noti.destroy();
    }

    componentDidMount() {

        this.props.propApiTaskStatusesGetAll();
        this.props.propApiTasksGetAll();
        this.props.propApiContactPersonGetAll();
        this.props.propApiCompanyGetAll();

        this.props.propApiTaskPrioritiesGetAll();
        this.props.propApiTaskOwnersGetAll();

        NotificationSystem.newInstance({}, n => this.noti = n);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lastChangeId !== this.props.lastChangeId && this.state.files.length > 0){
            this.uploadFiles(this.props.lastTaskId)



        }else if(prevProps.lastChangeId !== this.props.lastChangeId){

                switch(this.state.last_operation){
                    case 1:
                        this.showNotification('Zadanie dodane pomyślnie ','primary');
                        break;
                    case 2:
                        this.showNotification('Zadanie zaktualizowane pomyślnie ','primary');
                        break;
                    case 3:
                        this.showNotification('Zadanie usunięte pomyślnie ','primary');
                        break;
                }

                this.setState({
                    last_operation:0
                })

        }else if(!prevProps.fileJustUploaded  && this.props.fileJustUploaded){

            this.props.propSetLastFileUploaded(false);

            switch(this.state.last_operation){
                case 1:
                    this.showNotification('Zadanie dodane pomyślnie z załącznikami ','primary');
                    break;
                case 2:
                    this.showNotification('Zadanie zaktualizowane pomyślnie z załącznikami ','primary');
                    break;

            }

            this.setState({
                last_operation:0
            })
        }
    }



    render(){

        const data = this.generateData()

        const bootstrap_rate =  this.state.opened?8:12;

        return(
                <Container className="dashboard">
                    <Header subTitle={`Lista wszystkich zadań znajdujących się w ekosystemie MuchMore`}  title={`Zadania`} />
                    <Buttons add={this.addHandler}/>
                    <Row className={classNames({
                        opened:this.state.opened
                    })}>
                        <Col xl={bootstrap_rate}  lg={bootstrap_rate} md={bootstrap_rate} sm={12} xs={12} style={{marginLeft:"0px",marginRight:"0px",paddingLeft:"5px", paddingRight:"10px"}}>

                            {
                                (this.props.loading_tasks)?
                                    (
                                        <div style={{textAlign:"center", marginTop:'200px'}}>
                                            <svg className="load__icon">
                                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                            </svg>
                                        </div>

                                    )
                                    :
                                    (
                                        <Board
                                            style={{backgroundColor:"#f2f4f7",marginTop:'20px'}}
                                            data={data}
                                            onDataChange={this.changeHandler}
                                            handleDragEnd = {this.dragHandler}
                                            components = {{Card:CustomCard}}
                                            onCardClick = {this.onTaskClickHandler}
                                        />
                                    )
                            }
                        </Col>
                        {
                            this.state.opened?
                                (
                                    <Col  xl={4}  lg={4} md={4} sm={12} xs={12} style={{marginTop: "28px", marginLeft:"0px",marginRight:"0px",paddingLeft:"10px",paddingRight:"5px",borderLeft:"1px solid rgba(153, 153, 153, 0.3)"}}>

                                            <TaskEditCard
                                                task = {this.props.selectedTask}
                                                companies = {this.props.companies}
                                                statuses = {this.props.statuses}
                                                owners = {this.props.owners}
                                                priorities = {this.props.priorities}

                                                contactPersons = {this.props.contactPersons}
                                                contact_person_loading = {this.props.contact_person_loading}
                                                company_loading = {this.props.company_loading}

                                                onSave = {this.onSaveHandler}
                                                onDelete = {this.onDeleteHandler}
                                                onRateChange = {this.onRateChangeHandler}
                                                onContactPersonChange = {this.onContactPersonChangeHandler}
                                                onCompanyChange = {this.onCompanyChangeHandler}
                                                onRealTimeChange = {this.onRealTimeChangeHandler}
                                                onEstimatedTimeChange = {this.onEstimatedTimeChangeHandler}
                                                onTaskOwnerChange = {this.onTaskOwnerChangeHandler}
                                                onDeadlineChange = {this.onDeadlineChangeHandler}
                                                onDrop = {this.onDropFileHandler}
                                                removeFile = {this.onFileRemoveHandler}
                                                onDescriptionChange = {this.onDescriptionChangeHandler}
                                                onNameChange = {this.onNameChangeHandler}

                                                onStatusChange = {this.onStatusChangeHandler}
                                                onPriorityChange = {this.onPriorityChangeHandler}

                                                files_loading = {this.props.files_loading}
                                                files = {this.state.files}

                                                downloadFile = {this.downloadFileHandler}



                                            />

                                    </Col>
                                )
                                :
                                null
                        }

                    </Row>
                </Container>


        )
    }

    generateData() {

       const lanes =  (this.props.statuses || []).map( s => ({
           id: `${s.id}`,
           title: s.name,
           label:'',
           editable:false,
           style: { backgroundColor: '#fff' },  // Style of Lane
           cardStyle: { backgroundColor: '#f2f4f7' }, // Style of Card
           cards:[]
       }));

       (this.props.tasks || []).forEach( t => {

            const taskLane = lanes.find(l => {
              return   parseInt(l.id) === parseInt(t.status.id)
            })


            if(taskLane !== undefined){
                taskLane.cards.push({
                    id: `${t.id}`,
                    title: `${t.name}`,
                    description: (<TaskCardDetails owner={(t.owner && t.owner.name || '')+' '+(t.owner && t.owner.surname || '')} description={t.description} deadline={t.deadline}  priority={t.priority} days={t.days} task_id={t.id} company={t.company && t.company.name || ''} />),
                   // description: (<a href={'#'}>Wstrzykiwanie html</a>),
                    label: `${t.realTime}/${t.estimatedTime}`,
                    draggable: true,
                })
            }

        });


        return {lanes:lanes}

        // const data ={
        //     lanes: [
        //         {
        //             id: 'lane1',
        //             title: 'Planned Tasks',
        //             label: '2/2',
        //             editable:true,
        //             style: { backgroundColor: 'yellow' },  // Style of Lane
        //             cardStyle: { backgroundColor: 'blue' }, // Style of Card
        //             cards: [
        //                 {id: 'Card1', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins', draggable: true, something:"dffd"},
        //                 {id: 'Card2', title: 'Pay Rent', description: 'Transfer via NEFT', label: '5 mins', metadata: {sha: 'be312a1'}}
        //             ]
        //         },
        //         {
        //             id: 'lane2',
        //             title: 'Completed',
        //             label: '0/0',
        //             cards: [],
        //             style: { backgroundColor: 'yellow' },  // Style of Lane
        //             cardStyle: { backgroundColor: 'blue' } // Style of Card
        //         }
        //     ]
        // }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Tasks)
