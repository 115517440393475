import React from 'react';
import { Card, CardBody, Col ,Table } from 'reactstrap';
import {Link} from "react-router-dom";

const ContactPersonTable = ({company}) => (
    <Col md={12}>
        <Card>
            <CardBody>
                <Table responsive={true} striped={false}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Imię</th>
                        <th>Nazwisko</th>
                        <th>Stanowisko</th>
                        <th>Numer telefonu</th>
                        <th>Email</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>

                        {
                            ((company && company.contactPeople) || []).map(cp=>(
                                <tr key={cp.id}>
                                    <td>{cp.id}</td>
                                    <td>{cp.name}</td>
                                    <td>{cp.surname}</td>
                                    <td>{cp.jobFunction}</td>
                                    <td>{cp.phone}</td>
                                    <td>{cp.email}</td>
                                    <td className="actions_button_container"><Link to={`/pages/contact-person/${cp.id}`}>Szczegóły</Link></td>
                                </tr>
                            ))
                        }


                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </Col>
);

export default ContactPersonTable;
