import React, { Component, Fragment } from 'react';
import {Card, CardBody, Col, Table, CardHeader} from "reactstrap";
import NotesTable from "../../CompanyDetails/Components/NotesTable";
import classNames from 'classnames';
import ContactPersonNotesTable from "./ContactPersonNotesTable";

export default class ContactPersonListCard extends Component{

    constructor(props){
        super(props);

    }


    render(){

        const navLinkClassNotes = classNames({
            "nav-link active": true,

        });


        const navItemClassNotes = classNames({
            "nav-item active": true,

        });


        return (

            <Card className="details_left_list">
                <CardBody>
                    <CardHeader>
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className={navItemClassNotes} >
                                <a className={navLinkClassNotes} href="#" >Notatki
                                    <span className="badge badge-pill badge-primary">{(this.props.contactPerson && this.props.contactPerson.notes && this.props.contactPerson.notes.length) || 0 }</span>
                                </a>

                            </li>
                        </ul>
                    </CardHeader>
                    <ContactPersonNotesTable showDetails={this.props.showDetails} contactPerson={this.props.contactPerson}/>
                </CardBody>
            </Card>

        )
    }

}