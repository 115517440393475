import {URL} from "./constants";

export const CLIENT_TYPE_ACTIONS = {

    SET_ALL_CLIENT_TYPES : 'SET_ALL_CLIENT_TYPES',
}

export const clientTypesSetAll = (data)=>({
        type:CLIENT_TYPE_ACTIONS.SET_ALL_CLIENT_TYPES,
        data
})

export const apiClientTypesGetAll = () => {

    return dispatch => {

        fetch(`${URL}v1/client-types`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(clientTypesSetAll(data.data))

                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}