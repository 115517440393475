import {URL} from "./constants";
import {apiCompanyGetAll, COMPANY_ACTIONS, companyRemove, companySetAll, companySetLoading} from "./companyActions";
import {apiContactPersonGetOne, contactPersonSetLoading} from "./contactPersonActions";
import {setLastChangeId} from "./taskActions";

export const NOTE_ACTIONS = {

    NOTE_REMOVE : 'NOTE_REMOVE',
    SET_LAST_NOTE_ID: 'SET_LAST_NOTE_ID',
    SET_FILES_LOADING: 'SET_FILES_LOADING',
    SET_NOTES_LOADING: 'SET_NOTES_LOADING',
    SET_ALL_FILES_UPLOADED_FLAG: 'SET_ALL_FILES_UPLOADED_FLAG',
    NOTES_SET_ALL: 'NOTES_SET_ALL'

};
export const noteRemove = id => ({
    type: NOTE_ACTIONS.NOTE_REMOVE,
    id
});
export const setLastNoteId = id => ({
    type: NOTE_ACTIONS.SET_LAST_NOTE_ID,
    id
});
export const setFilesLoading = tof => ({
    type: NOTE_ACTIONS.SET_FILES_LOADING,
    tof
});
export const setNotesLoading = tof => ({
    type: NOTE_ACTIONS.SET_NOTES_LOADING,
    tof
});
export const setNotesAllFilesUploadedFlag = tof => ({
    type: NOTE_ACTIONS.SET_ALL_FILES_UPLOADED_FLAG,
    tof
});

export const setNotes = notes => ({
    type: NOTE_ACTIONS.NOTES_SET_ALL,
    notes
});




export const apiNotesGetAll = () => {

    return dispatch => {

        dispatch(setNotesLoading(true))
        fetch(`${URL}v1/notes`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(setNotesLoading(false))

            if (response.status === 200 && response.headers.get("content-type") === 'application/json') {

                response.json().then(data => {

                    dispatch(setNotes(data.data))


                }).catch(error => {
                    alert('error');
                })

            }else if(response.status === 401){
                //nothing intercept to login page
            }else{
                dispatch(setNotesLoading(false))
                alert("error");
            }
        })
    }
}


export const apiNoteCreateNew = (text, company_id, contact_person_id = null, created_at = null) => {

    return dispatch => {

        dispatch(setNotesLoading(true))
        dispatch(setNotesAllFilesUploadedFlag(false));

        fetch(`${URL}v1/note`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({text:text, company_id:company_id, contact_person_id:contact_person_id, created_at:created_at, user_id:localStorage.getItem('user_id')})


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                 //   alert('Note created successfully');

                    dispatch(setNotesLoading(false))

                    dispatch(apiCompanyGetAll())
                    if(contact_person_id !== null){
                        dispatch(apiContactPersonGetOne(contact_person_id))
                    }
                    dispatch(setLastNoteId(parseInt(data.data)))



                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}

export const apiNoteDelete = (id, contact_person_id = null, from_list = false) => {



    return dispatch => {



        dispatch(companySetLoading(true))
        dispatch(contactPersonSetLoading(true))
        dispatch(setNotesLoading(true))

        fetch(`${URL}v1/note/${id}`,{

            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){



                dispatch(setNotesLoading(false))
                //dispatch(noteRemove(id));
                dispatch(companySetLoading(false))
                dispatch(contactPersonSetLoading(false))

                if(!from_list){
                    dispatch(apiCompanyGetAll())
                    if(contact_person_id !== null){
                        dispatch(apiContactPersonGetOne(contact_person_id))
                    }
                }


                dispatch(apiNotesGetAll())

                //alert('note has been removed');

            }else{

                alert("error");
                dispatch(companySetLoading(false))
                dispatch(setNotesLoading(false))
            }
        })
    }
}


export const apiFileUpload = (binaryStr,filename,size,noteId, last = false)=> {

    return dispatch => {

        dispatch(setNotesLoading(true))
        dispatch(setFilesLoading(true))

        fetch(`${URL}v1/file/upload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body:encodeURIComponent("filename")+"="+encodeURIComponent(filename)
                +"&"+encodeURIComponent("binaryStr")+"="+encodeURIComponent(binaryStr)
                +"&"+encodeURIComponent("size")+"="+encodeURIComponent(size)
                +"&"+encodeURIComponent("noteId")+"="+encodeURIComponent(noteId)

        }).then(response=>{

            if(response.status === 200 &&  response.headers.get("content-type") === 'application/json'){



               if(last){
                   dispatch(setNotesLoading(false))
                   dispatch(setFilesLoading(false))
                   dispatch(setNotesAllFilesUploadedFlag(true));
               }

            }
        }).catch(error=>{

            dispatch(setNotesLoading(false))
            dispatch(setFilesLoading(false))
            alert('error');

        })
    }
}

