import React from 'react';
import { Card, CardBody, Col ,Table } from 'reactstrap';
import {Link} from "react-router-dom";

const ContactPersonCard = ({contactPersons}) => (
  <Col md={12}>
    <Card>
      <CardBody>
            <Table responsive={true} striped={false}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Imię</th>
                        <th>Nazwisko</th>
                        <th>Stanowisko</th>
                        <th>Numer telefonu</th>
                        <th>Email</th>
                        <th>Firma</th>
                        <th>Akcje</th>

                    </tr>
                </thead>
                <tbody>

                {contactPersons.map((c)=>(
                    <tr key={c.id}>
                        <td>{c.id}</td>
                        <td>{c.name}</td>
                        <td>{c.surname}</td>
                        <td>{c.jobFunction}</td>
                        <td>{c.phone}</td>
                        <td>{c.email}</td>
                        <td>{(c.company && c.company.name ) || ''}</td>
                        <td className="actions_button_container"><Link to={`/pages/contact-person/${c.id}`}>Szczegóły</Link></td>
                    </tr>
                ))}
                </tbody>
            </Table>
      </CardBody>
    </Card>
  </Col>
);

export default ContactPersonCard;
