import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {withRouter} from 'react-router-dom';


class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToDark, changeToLight } = this.props;
    return (
      <div className="sidebar__content">

        <ul className="sidebar__block">
          <SidebarCategory active={location.pathname &&  location.pathname.includes('/pages/companies')} title="Firmy" href={"/pages/companies"} icon="apartment" />
          <SidebarCategory active={location.pathname &&  location.pathname.includes('/pages/contact-persons')} title="Osoby kontaktowe" icon="users" href={"/pages/contact-persons"}/>
          <SidebarCategory active={location.pathname &&  location.pathname.includes('/pages/notes')} title="Notatki" icon="paperclip" href={"/pages/notes"} />
          <SidebarCategory active={location.pathname &&  location.pathname.includes('/pages/tasks')} title="Zadania" icon="layers"  >
                <SidebarLink title="Lista" route="/pages/tasks/board" onClick={this.hideSidebar} />
                <SidebarLink title="Tabela" route="/pages/tasks/table" onClick={this.hideSidebar} />
          </SidebarCategory>
        {/*  <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={this.hideSidebar} />*/}
        {/*  <SidebarCategory title="Layout" icon="layers">*/}
        {/*    <button type="button" className="sidebar__link" onClick={changeToLight}>*/}
        {/*      <p className="sidebar__link-title">Light Theme</p>*/}
        {/*    </button>*/}
        {/*    <button type="button" className="sidebar__link" onClick={changeToDark}>*/}
        {/*      <p className="sidebar__link-title">Dark Theme</p>*/}
        {/*    </button>*/}
        {/*  </SidebarCategory>*/}
        {/*</ul>*/}
        {/*<ul className="sidebar__block">*/}
        {/*  <SidebarCategory title="Example Pages" icon="diamond">*/}
        {/*    <SidebarLink title="Page one" route="/pages/one" onClick={this.hideSidebar} />*/}
        {/*    <SidebarLink title="Page two" route="/pages/two" onClick={this.hideSidebar} />*/}
        {/*  </SidebarCategory>*/}
        </ul>
      </div>
    );
  }
}

export default withRouter(SidebarContent);
