import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import logo from '../../shared/img/logo/Logo_MM_hori.svg'
import {URL} from "../../redux/actions/constants";
import {apiCompanyGetAll} from "../../redux/actions/companyActions";
import {apiContactPersonGetOne} from "../../redux/actions/contactPersonActions";
import {BasicNotification, FullWideNotification} from "../../shared/components/Notification";
import NotificationSystem from "rc-notification/es";

let notificationRU = null;

const showNotification = ({ notification, position }) => {

            notificationRU.notice({
                content: notification,
                duration: 5,
                closable: true,
                style: { top: 0, left: 'calc(100vw - 100%)' },
                className: position,
            });

};


export default class LogIn extends React.Component {


    componentDidMount() {

        NotificationSystem.newInstance({}, n => this.noti = n);

    }

    componentWillUnmount() {
        this.noti.destroy();
    }


    constructor(props){
        super(props);

        this.noti = null;

        this.state = {error:false, loading:false}

    }

    show = (color) => {

            this.noti.notice({
                content: <BasicNotification
                    color={color}
                    title="Złe dane do logowania"
                    message="Niestety wprowadzony login lub hasło jest błędne"
                />,
                duration: 3,
                closable: true,
                style: { top: 0, left: 0,zIndex:1000,position:'fixed' },
                className: 'full',
            });


    };

    render() {
        return(
            <div className="account">
                <div className="account__wrapper">
                    <div className="account__card">
                        <div className="account__head">
                            <img className={'img-responsive'} src={logo} alt="Muchmore"/>
                            {/*<h3 className="account__title">Welcome to*/}
                            {/*  <span className="account__logo"> Easy*/}
                            {/*    <span className="account__logo-accent">DEV</span>*/}
                            {/*  </span>*/}
                            {/*</h3>*/}
                            {/*<h4 className="account__subhead subhead">Start your business easily</h4>*/}
                        </div>
                        <LogInForm
                            onSubmit={this.handleSubmit}
                            error={this.state.error}
                            loading={this.state.loading}

                        />
                    </div>
                </div>
            </div>
        )
    }

    handleSubmit = (credentials)=>{



        if(credentials.email === undefined || credentials.email === null){
            return;
        }
        this.setState({
            loading:true
        })
        fetch(`${URL}v1/user/login`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...credentials})


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{


                    this.setState({
                        loading:false,
                        errors:false
                    })


                    localStorage.setItem('token',data.data.token);
                    localStorage.setItem('user_id',data.data.user_id);
                    localStorage.setItem('name',data.data.name);
                    localStorage.setItem('surname',data.data.surname);

                    window.location.href = '/';

                }).catch(error=>{alert('error');})

            }else{
                this.show('danger')
                this.setState({
                    error:true,
                    loading:false
                })

            }
        })
    }
}


