import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import GoogleFontLoader from "react-google-font-loader";
import fetchIntercept from 'fetch-intercept';


const unregister = fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
       // config.mode = 'no-cors';

        if(localStorage.getItem('token') !== null && !url.includes('user/login')){
            const token =  localStorage.getItem('token');
            if(config.headers !== undefined){
                config.headers = {...config.headers, 'X-AUTH-TOKEN':token}
            }else{
                config.headers = {'X-AUTH-TOKEN':token}
            }
        }

        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {

        if(response.status === 401 ){
            window.location.href = '/log_in';
        }

        // Modify the reponse object
        return response;
    },

    responseError: function (error) {

        // Handle an fetch error
        return Promise.reject(error);
    }
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

    componentWillUnmount(){
        unregister();
    }

  render() {


    const { loaded, loading } = this.state;
    return (

      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
              <Fragment>
                  <GoogleFontLoader
                      fonts={[

                          {
                              font: 'Open Sans',
                              weights: [300,'300i',400,'400i',600,'600i',700,'700i',800,'800i'],
                          },
                          {
                              font: 'Exo 2',
                              weights: [300,'300i',400,'400i',600,'600i',700,'700i',800,'800i',900,'900i'],
                          },
                      ]}

                  />

                {!loaded
                && (
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
                )
                }
                <div>
                  <Router />
                </div>
              </Fragment>

          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
