import React from 'react';
import { Card, CardBody, Col ,Table } from 'reactstrap';
import {Link} from "react-router-dom";

const CompanyCard = ({companies}) => (
  <Col md={12}>
    <Card>
      <CardBody>
            <Table responsive={true} striped={false}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nazwa</th>
                        <th>Typ</th>
                        <th>Status</th>
                        <th>Numer telefonu</th>
                        <th>Email</th>
                        <th>Data następnego kontaktu</th>
                        <th>Akcje</th>
                    </tr>
                </thead>
                <tbody>

                {companies.map((c)=>(
                    <tr key={c.id}>
                    <td>{c.id}</td>
                    <td>{c.name}</td>
                    <td>{(c.clientType && c.clientType.name)|| ''}</td>
                    <td>{(c.status && c.status.name) || ''}</td>
                    <td>{c.phone}</td>
                    <td>{c.email}</td>
                    <td>{c.nextContractDate}</td>
                    <td className="actions_button_container"><Link to={`/pages/company/${c.id}`}>Szczegóły</Link></td>

                </tr>
                ))}
                </tbody>
            </Table>
      </CardBody>
    </Card>
  </Col>
);

export default CompanyCard;
