import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const ButtonsNotes  = (props) => {

    return(
        <Row className="align-items-center justify-content-between top_buttons_container">
            <Col md={6} lg={6} className="buttons_sub_container justify-content-start" >
               <div className="actions_button_container blue"><a href="#" onClick={props.onNoteAdded} >Dodaj notatkę</a></div>
            </Col>

        </Row>
    )
}

export default ButtonsNotes;