import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const ButtonsCompanies  = (props) => {

    return(
        <Row className="align-items-center justify-content-between top_buttons_container">
            <Col md={6} lg={6} className="buttons_sub_container justify-content-start" >
                <div className="actions_button_container blue"><Link to={"/pages/new/company/"}>Dodaj nową firmę</Link></div>
            </Col>

        </Row>
    )
}

export default ButtonsCompanies;