import {ContactPersonDisconnected} from "./index";
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import Header from "../../shared/header";
import ContactPersonEditCard from "./Components/ContactPersonEditCard";
import React from "react";
import ButtonsCreateMode from "../CompanyDetails/Components/ButtonsCreateMode";
import {
    apiCompanyCreateNew,
    apiCompanyGetAll,
    apiCompanyGetOne,
    companySetOne
} from "../../redux/actions/companyActions";
import {apiClientTypesGetAll} from "../../redux/actions/clientTypeActions";
import {apiTagsGetAll} from "../../redux/actions/tagActions";
import {apiGroupsGetAll} from "../../redux/actions/groupActions";
import {apiBrandsGetAll} from "../../redux/actions/brandActions";
import {apiStatusesGetAll} from "../../redux/actions/statusActions";
import {apiContactPersonCreateNew, contactPersonSetOne} from "../../redux/actions/contactPersonActions";
import NotificationSystem from "rc-notification/es";


const mapStateToProps = (state) => {
    return {
        contactPerson: state.contactPersons.selectedContactPerson,
        contactPersons: state.contactPersons.contactPersons,
        errors: state.contactPersons.errors,
        companies: state.company.companies,
        selectedCompany: state.company.selectedCompany,
        success:state.contactPersons.success,
        loading:state.contactPersons.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        propApiCompanyGetOne: (id)=> dispatch(apiCompanyGetOne(id)),
        propApiCompanyGetAll: ()=> dispatch(apiCompanyGetAll()),
        propApiContactPersonCreateNew: (contactPerson)=> dispatch(apiContactPersonCreateNew(contactPerson)),
        propContactPersonSetOne: (contactPerson)=> dispatch(contactPersonSetOne(contactPerson)),

    }
}


class NewContactPerson extends ContactPersonDisconnected{


    constructor(props){
        super(props);

        this.noti = null;

    }

    componentDidMount() {

       if(this.props.selectedCompany !== null){
            this.props.propContactPersonSetOne({company_id:this.props.selectedCompany.id,company:{...this.props.selectedCompany}});
       }else{
           this.props.propContactPersonSetOne({});
       }

       this.props.propApiCompanyGetAll();
       NotificationSystem.newInstance({}, n => this.noti = n);

    }


    render(){


        if(this.props.loading){
            return (
                <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }


        return (

            <Container className="dashboard">
                <Header subTitle="Dodaj nową osobę kontaktową" title={`Dane osoby kontaktowej`} />
                <Row>
                    <Col xl={5}  lg={5} md={12} sm={12} xs={12}>
                        <ContactPersonEditCard

                            contact_person = {this.props.contactPerson}
                            errors = {this.props.errors}
                            companies = {this.props.companies}
                            onChangeField = {(e,fieldId)=>this.changeSelectedContactPersonField(e,fieldId)}
                            edit = {true}
                            showNotification = {this.showNotification}

                        />
                    </Col>
                </Row>
                <ButtonsCreateMode  onSaveClick={(e)=>this.saveClickHandler(e)} />

            </Container>
        )

    }

    saveClickHandler(e) {
        e.preventDefault();
        if(this.props.contactPerson.company !== null){
            this.props.propApiContactPersonCreateNew({...this.props.contactPerson,company_id:this.props.contactPerson.company.id})
        }else{
            this.props.propApiContactPersonCreateNew({...this.props.contactPerson})
        }
        this.setState({
            after_action:true
        })

    }

}


export default connect(mapStateToProps,mapDispatchToProps)(NewContactPerson)
