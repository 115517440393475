import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withRouter from "react-router/withRouter";

class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,

    active:PropTypes.bool,
    href:PropTypes.string,
    history:PropTypes.any

  };

  static defaultProps = {
    icon: '',
    isNew: false,
    active:false,
    href:'/'
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
   if(this.props.children){
     this.setState(prevState => ({ collapse: !prevState.collapse }));
   }

  };

  gotoHref = () => {
      if(this.props.children){
        this.toggle();
        return false;
      }
      this.props.history.push(this.props.href);
  }

  render() {
    const {
      title, icon, isNew, children,active
    } = this.props;
    const { collapse } = this.state;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
      'sidebar__category-wrap--active': active,
    });

    return (
      <div className={categoryClass}>
        <button type="button" className="sidebar__link sidebar__category" onClick={this.gotoHref}>
          {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
          <p className="sidebar__link-title">{title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>
              {children}
            </div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
export default withRouter(SidebarCategory)
