import {URL} from "./constants";

export const BRAND_ACTIONS = {

    SET_ALL_BRANDS : 'SET_ALL_BRANDS',
}

export const brandsSetAll = (data)=>({
    type:BRAND_ACTIONS.SET_ALL_BRANDS,
    data
})

export const apiBrandsGetAll = () => {

    return dispatch => {

        fetch(`${URL}v1/brands`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(brandsSetAll(data.data))

                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}