import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const ContactPersonButtons  = (props) => {

    return(
        <Row className="align-items-center justify-content-between top_buttons_container">
            <Col md={6} lg={6} className="buttons_sub_container" >
                {
                    props.editMode?

                        ( <div className="actions_button_container blue"><a href="#" onClick={(e)=>props.onSaveClick(e)}>Zapisz</a></div>)

                        :

                        ( <div className="actions_button_container blue"><a href="#" onClick={(e)=>props.onEditClick(e,true)}>Edytuj</a></div>)
                }


                <div className="actions_button_container red"><a href="#" onClick={(e)=>props.onDeleteContactPerson(e)} >Usuń</a></div>
            </Col>
            <Col md={6} lg={6} className="buttons_sub_container" >
                {

                    props.contactPerson !== null?
                        (<div className="actions_button_container yellow"><Link to={`/pages/new/note/${(props.contactPerson && props.contactPerson.id) || ''}`}>Dodaj notatkę </Link></div>)
                        :
                        null
                }


            </Col>
        </Row>
    )
}

export default ContactPersonButtons;