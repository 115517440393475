import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {
    apiCompanyCreateNew,
    apiCompanyGetOne,
    apiCompanyUpdate,
    companySetOne
} from "../../redux/actions/companyActions";
import {apiClientTypesGetAll} from "../../redux/actions/clientTypeActions";
import {apiTagsGetAll} from "../../redux/actions/tagActions";
import {apiGroupsGetAll} from "../../redux/actions/groupActions";
import {apiBrandsGetAll} from "../../redux/actions/brandActions";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Header from "../../shared/header";
import Buttons from "./Components/Buttons";
import EditCard from "./Components/EditCard";
import ListCard from "./Components/ListCard";
import CompanyDetails from "./index";
import ButtonsCreateMode from "./Components/ButtonsCreateMode";
import {CompanyDetailsDisconnected} from "./index";
import {apiStatusesGetAll} from "../../redux/actions/statusActions";
import NotificationSystem from "rc-notification/es";


const mapStateToProps = (state) => {

    return {
        company: state.company.selectedCompany,
        errors: state.company.errors,
        success: state.company.success,
        clientTypes: state.clientTypes.clientTypes,
        tags: state.tags.tags,
        groups: state.groups.groups,
        brands: state.brands.brands,
        statuses: state.statuses.statuses,
        loading: state.company.loading,
        companies: state.company.companies,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        propApiCompanyGetOne: (id) => dispatch(apiCompanyGetOne(id)),
        propApiClientTypesGetAll: () => dispatch(apiClientTypesGetAll()),
        propApiTagsGetAll: () => dispatch(apiTagsGetAll()),
        propApiGroupsGetAll: () => dispatch(apiGroupsGetAll()),
        propApiBrandsGetAll: () => dispatch(apiBrandsGetAll()),
        propCompanySetOne: (company) => dispatch(companySetOne(company)),
        propApiCompanyCreateNew: (company) => dispatch(apiCompanyCreateNew(company)),
        propApiStatusesGetAll: () => dispatch(apiStatusesGetAll()),

    }
}


class NewCompany extends CompanyDetailsDisconnected {


    constructor(props) {
        super(props);
        this.noti = null;
    }

    componentDidMount() {

        this.props.propCompanySetOne({});
        this.props.propApiClientTypesGetAll();
        this.props.propApiTagsGetAll();
        this.props.propApiGroupsGetAll();
        this.props.propApiBrandsGetAll();
        this.props.propApiStatusesGetAll();

        NotificationSystem.newInstance({}, n => this.noti = n);
    }

    render() {

        if (this.props.loading) {
            return (
                <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                        </svg>
                    </div>
                </div>
            )
        }

        return (
            <Container className="dashboard">
                <Header subTitle={null} title={`Nowa firma`}/>

                <Row>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <EditCard
                            company={this.props.company}
                            clientTypes={this.props.clientTypes}
                            tags={this.props.tags}
                            groups={this.props.groups}
                            brands={this.props.brands}
                            statuses={this.props.statuses}
                            edit={true}
                            onChangeField={(e, fieldId) => this.changeSelectedCompanyField(e, fieldId)}
                            onChangeMultiTags={this.onChangeMultiTagsHandler}
                            onChangeMultiGroups={this.onChangeMultiGroupsHandler}
                            errors={this.props.errors}
                            showNotification={this.showNotification}
                        />
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                        <Card>
                            <CardBody className='card-disabled text-disabled'>
                                <p>Dodatkowe opcje będą dostępne po zapisaniu firmy</p>
                            </CardBody>
                        </Card>
                    </Col>
                        <ButtonsCreateMode onSaveClick={(e) => this.saveClickHandler(e)}/>
                </Row>
                < /Container>
                    )
                    }

                    saveClickHandler(e) {
                    e.preventDefault();

                    this.props.propApiCompanyCreateNew(this.props.company)
                    this.setState({
                    after_action: true
                })
                }

                    }

                    export default connect(mapStateToProps, mapDispatchToProps)(NewCompany)
