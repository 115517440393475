import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import Header from "../../shared/header";
import Buttons from "../CompanyDetails/Components/Buttons";
import EditCard from "../CompanyDetails/Components/EditCard";
import ListCard from "../CompanyDetails/Components/ListCard";
import ButtonsNotes from "./Components/Buttons";
import NoteCard from "./Components/NoteCard";
import {apiFileUpload, apiNoteCreateNew, setNotesAllFilesUploadedFlag} from "../../redux/actions/noteActions";
import NoteAddition from "./Components/NoteAddition";
import {apiCompanyGetAll, apiCompanyGetOne} from "../../redux/actions/companyActions";
import {apiContactPersonGetOne, contactPersonSetOne} from "../../redux/actions/contactPersonActions";
import {arrayBufferToBase64} from "../../shared/functions/fileFunctions";
import NotificationSystem from "rc-notification/es";
import {FullWideNotification} from "../../shared/components/Notification";
import {withRouter} from "react-router-dom";


const mapStateToProps = (state) => {

    return {
        upload_success: state.notes.upload_success,
        company: state.company.selectedCompany,
        companies: state.company.companies,
        selectedContactPerson: state.contactPersons.selectedContactPerson,
        last_note_id: state.notes.lastNoteId,
        company_loading: state.company.loading,
        contact_person_loading:  state.contactPersons.loading,
        notes_loading: state.notes.loading_notes,
        files_loading: state.notes.loading_files,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        propsApiNoteCreateNew: (text, company_id, contact_person_id, created_at) => dispatch(apiNoteCreateNew(text, company_id, contact_person_id,created_at)),
        propApiCompanyGetAll: ()=> dispatch(apiCompanyGetAll()),
        propApiCompanyGetOne: (id) => dispatch(apiCompanyGetOne(id)),
        propApiContactPersonGetOne: (id) => dispatch(apiContactPersonGetOne(id)),
        propApiFileUpload: (binaryStr,filename,size,noteId,last) => dispatch(apiFileUpload(binaryStr,filename,size,noteId,last)),
        propSetNotesAllFilesUploadedFlag: (tof) => dispatch(setNotesAllFilesUploadedFlag(tof)),
        propContactPersonSetOne: (cp) => dispatch(contactPersonSetOne(cp))

    }
}

export class NotesDisconnected extends Component{


    componentDidUpdate(prevProps) {



        if(prevProps.last_note_id !== this.props.last_note_id ){


            if(this.state.files.length === 0){

                    this.showNotification('Notatka została dodana prawidłowo','primary');
                    setTimeout(()=>{

                        if(location.pathname &&  location.pathname.includes('/pages/new/note/from-list')){
                            this.props.history.push('/pages/notes');
                        }else{
                            if(this.props.selectedContactPerson === null){
                                this.props.history.push(`/pages/company/${this.props.company.id}`);
                            }else{
                                this.props.history.push(`/pages/contact-person/${this.props.selectedContactPerson .id}`);
                            }

                        }



                    },3000);


            }else{
                this.uploadFiles(this.props.last_note_id)
            }
        }

        if(!prevProps.upload_success && this.props.upload_success  ){

            this.props.propSetNotesAllFilesUploadedFlag(false);
            this.showNotification('Notatka z załącznikami została dodana prawidłowo','primary');
            setTimeout(()=>{

                if(location.pathname &&  location.pathname.includes('/pages/new/note/from-list')){
                    this.props.history.push('/pages/notes');
                }else{
                    if(this.props.selectedContactPerson === null){
                        this.props.history.push(`/pages/company/${this.props.company.id}`);
                    }else{
                        this.props.history.push(`/pages/contact-person/${this.props.selectedContactPerson .id}`);
                    }

                }

            },3000);
        }
    }

    showNotification = (text,color) => {
        this.noti.notice({
            content: <FullWideNotification
                color={color}
                message={text}
            />,
            duration: 3,
            closable: true,
            style: { top: 0, left: 0,zIndex:1000,position:'fixed' },
            className: 'full',
        });
    }

    companyChangeHandler = (company) => {
        this.props.propApiCompanyGetOne(parseInt(company.value))
    }
    contactPersonChangeHandler = (ct) => {
        this.props.propApiContactPersonGetOne(parseInt(ct.value))
    }
    createdAtChangeHandler = (e) => {

        let value = e.target.value;
        this.setState((prevState)=>{
            console.log({...prevState.note,createdAt:value});
            return {
                note: {...prevState.note,createdAt:value}
            }
        })
    };
    onDropHandler = (files) => {
      //  console.log(files);
        this.setState(prevState=>({
            files:[...prevState.files,...files]
        }))
    }
    onRemoveHandler = (f,e) => {
        this.setState(prevState => {

            let filteredFiles = prevState.files.filter(fi => {
                return fi.name !== f.name
            })

            return {
                files: filteredFiles
            }
        })
    };

    componentDidMount() {

        this.props.propApiCompanyGetAll();
        this.props.propContactPersonSetOne(null);

        NotificationSystem.newInstance({}, n => this.noti = n);
    }
    componentWillUnmount() {
        this.noti.destroy();
    }


    onNoteAddedHandler = (e)=>{
        e.preventDefault();

        this.props.propsApiNoteCreateNew(this.state.note.text,this.props.company.id, (this.props.selectedContactPerson && this.props.selectedContactPerson.id) || null, this.state.note.createdAt)

    }
    
    uploadFiles = noteId => {
        let len = this.state.files.length;
        let last = false;

        for(let i =0; i < len; i++) {

            const reader = new FileReader()
            reader.onabort = () => alert('aborted')
            reader.onerror = () => alert('error')
            reader.onload = () => {
                const binaryStr = reader.result;
                this.props.propApiFileUpload(
                    arrayBufferToBase64(binaryStr),
                    this.state.files[i].name,
                    this.state.files[i].size,
                    noteId,
                    i === len -1
                )
                if(i === len -1){
                    this.setState({files:[]})
                }

            }
            reader.readAsArrayBuffer(this.state.files[i])
        }
    }

    onNoteChangeHandler = (data)=>{



        this.setState((prevState)=>({
            note: {...prevState.note, text:data}
        }))

    }

    constructor(props){
        super(props);

        this.noti = null;

        this.state = {
            note: {text:'',createdAt:null},
            files:[]
        }
    }

    arrayBufferToBase64( buffer ) {
        let binary = '';
        let bytes = new Uint8Array( buffer );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    render(){
        return (

            <Container className="dashboard">
                <Header subTitle={(this.props.company && this.props.company.name) || null} title={`Nowa notatka`} />
                <Row>
                    <Col xl={8}  lg={8} md={12} sm={12} xs={12}>
                        <NoteCard
                            company= {this.props.company}
                            onNoteChange = {this.onNoteChangeHandler}
                            note = {this.state.note}

                        />
                    </Col>

                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <NoteAddition
                            files = {this.state.files}
                            companies = {this.props.companies}
                            selectedCompany = {this.props.company}
                            selectedContactPerson = {this.props.selectedContactPerson}
                            companyChange = {this.companyChangeHandler}
                            contactPersonChange = {this.contactPersonChangeHandler}
                            createdAtChange = {this.createdAtChangeHandler}
                            note = {this.state.note || ''}
                            onDrop = {this.onDropHandler}
                            removeFile = {this.onRemoveHandler}
                            company_loading = {this.props.company_loading}
                            contact_person_loading = {this.props.contact_person_loading}
                            notes_loading = {this.props.notes_loading}
                            files_loading = {this.props.files_loading}
                        />
                    </Col>
                </Row>
                {
                    this.props.notes_loading?
                        (
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                        )
                        :
                        (
                            <ButtonsNotes onNoteAdded={this.onNoteAddedHandler}/>
                        )
                }
            </Container>
        )
    }


}



export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NotesDisconnected))
