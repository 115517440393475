import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import Header from "../../shared/header";
import Buttons from "../CompanyDetails/Components/Buttons";
import EditCard from "../CompanyDetails/Components/EditCard";
import ListCard from "../CompanyDetails/Components/ListCard";
import ButtonsNotes from "./Components/Buttons";
import NoteCard from "./Components/NoteCard";
import {apiFileUpload, apiNoteCreateNew, setNotesAllFilesUploadedFlag} from "../../redux/actions/noteActions";
import {withRouter} from "react-router-dom";
import NoteAddition from "./Components/NoteAddition";
import {apiCompanyGetAll, apiCompanyGetOne} from "../../redux/actions/companyActions";
import {apiContactPersonGetOne} from "../../redux/actions/contactPersonActions";
import {NotesDisconnected} from "./index";
import NotificationSystem from "rc-notification/es";


const mapStateToProps = (state) => {

    return {
        selectedContactPerson: state.contactPersons.selectedContactPerson,
        company: state.company.selectedCompany,
        companies: state.company.companies,
        last_note_id: state.notes.lastNoteId,
        company_loading: state.company.loading,
        contact_person_loading:  state.contactPersons.loading,
        notes_loading: state.notes.loading_notes,
        files_loading: state.notes.loading_files,
        upload_success: state.notes.upload_success,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        propsApiNoteCreateNew: (text, company_id, contact_person_id, created_at) => dispatch(apiNoteCreateNew(text, company_id, contact_person_id,created_at)),
        propApiCompanyGetAll: ()=> dispatch(apiCompanyGetAll()),
        propApiCompanyGetOne: (id) => dispatch(apiCompanyGetOne(id)),
        propApiContactPersonGetOne: (id) => dispatch(apiContactPersonGetOne(id)),
        propApiFileUpload: (binaryStr,filename,size,noteId,last) => dispatch(apiFileUpload(binaryStr,filename,size,noteId,last)),
        propSetNotesAllFilesUploadedFlag: (tof) => dispatch(setNotesAllFilesUploadedFlag(tof))

    }
}

export class ContactPersonNotesDisconnected extends NotesDisconnected{


    componentDidMount() {

        this.props.propApiCompanyGetAll();
        NotificationSystem.newInstance({}, n => this.noti = n);
    }

    constructor(props){
        super(props);
        this.state = {
            note: {text:'',createdAt:null},
            files:[]
        }
    }

    render(){
        return (

            <Container className="dashboard">
                <Header subTitle={(this.props.contactPerson && this.props.contactPerson.company && this.props.contactPerson.company.name) || null} title={`Nowa notatka`} />
                <Row>
                    <Col xl={8}  lg={8} md={12} sm={12} xs={12}>
                        <NoteCard
                            contactPerson= {this.props.contactPerson}
                            onNoteChange = {this.onNoteChangeHandler}
                            note = {this.state.note}
                        />
                    </Col>

                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <NoteAddition
                            files = {this.state.files}
                            companies = {this.props.companies}
                            selectedCompany = {this.props.company}
                            selectedContactPerson = {this.props.selectedContactPerson}
                            companyChange = {this.companyChangeHandler}
                            contactPersonChange = {this.contactPersonChangeHandler}
                            createdAtChange = {this.createdAtChangeHandler}
                            note = {this.state.note || ''}
                            onDrop = {this.onDropHandler}
                            removeFile = {this.onRemoveHandler}
                            company_loading = {this.props.company_loading}
                            contact_person_loading = {this.props.contact_person_loading}
                            notes_loading = {this.props.notes_loading}
                            files_loading = {this.props.files_loading}

                        />
                    </Col>
                </Row>
                {
                    this.props.notes_loading?
                        (
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                        )
                        :
                        (
                            <ButtonsNotes onNoteAdded={this.onNoteAddedHandler}/>
                        )
                }


            </Container>
        )
    }


}



export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ContactPersonNotesDisconnected))
