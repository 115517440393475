import React, { Component, Fragment } from 'react';
import {Col, Container, Row} from "reactstrap";
import ExampleCard from "../Example/components/ExampleCard";
import GoogleFontLoader from "react-google-font-loader";
import CompanyCard from "./components/CompanyCard";
import Header from "../../shared/header";
import {apiCompanyGetAll} from "../../redux/actions/companyActions";
import { connect } from 'react-redux'
import ButtonsCompanies from "./components/Buttons";



const mapStateToProps = (state) => {

    return {
        companies: state.company.companies,
        loading:state.company.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        propApiCompanyGetAll: ()=> dispatch(apiCompanyGetAll())
    }
}

class Companies extends Component{

    componentDidMount() {

        this.props.propApiCompanyGetAll();
    }

    render() {

            if(this.props.loading){
                return (
                    <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                        <div className="load__icon-wrap">
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                            </svg>
                        </div>
                    </div>
                )
            }

            return(

                <Container className="dashboard">
                    <Header subTitle={"Lista wszystkich firm znajdujących się w biznesowym ekosystemie MuchMore."} title={"Firmy"} />
                    <ButtonsCompanies/>
                    <Row>
                        <CompanyCard companies={this.props.companies}/>
                    </Row>
                </Container>

            )
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Companies)