import React, { Component, Fragment } from 'react';
import {Card, CardBody, Col, Table, Form,FormGroup,Label,Input} from "reactstrap";
import {Link} from "react-router-dom";
import {Field} from "redux-form";
import {MultiSelectField} from "../../../shared/components/form/MultiSelect";



export default class EditCard extends Component{


    constructor(props){

        super(props);
        this.state = {
           errors_list:{
               company_name: {is_invalid:false, message:""},
               company_nip: {is_invalid:false, message:""},
               company_phone: {is_invalid:false, message:""},
               company_email: {is_invalid:false, message:""},
               company_address: {is_invalid:false, message:""},
               company_postalCode: {is_invalid:false, message:""},
               company_country: {is_invalid:false, message:""},
               company_krs: {is_invalid:false, message:""},
               company_nextContractDate: {is_invalid:false, message:""},
               company_pricing: {is_invalid:false, message:""},
           }
        }

    }

    componentDidUpdate(prevProps) {
        if(((prevProps.errors &&  prevProps.errors.length) || 0) === ((this.props.errors &&  this.props.errors.length) || 0)){
            return;
        }


        if(((this.props.errors &&  this.props.errors.length) || 0) > 0){

            this.props.errors.forEach(e => {
                let text = e.message;
                this.props.showNotification(text,'danger');

            })
        }

        this.setState(prevState => {
            return {
                errors_list:{
                    ...prevState.errors_list,
                    company_name:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "name")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "name")) > -1 ? this.props.errors.find(e => (e.invalid_property === "name")).message || "" : ""
                    },
                    company_nip:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "nip")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "nip")) > -1 ? this.props.errors.find(e => (e.invalid_property === "nip")).message || "" : ""
                    },
                    company_phone:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "phone")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "phone")) > -1 ? this.props.errors.find(e => (e.invalid_property === "phone")).message || "" : ""
                    },
                    company_email:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "email")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "email")) > -1 ? this.props.errors.find(e => (e.invalid_property === "email")).message || "" : ""
                    },
                    company_address:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "address")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "address")) > -1 ? this.props.errors.find(e => (e.invalid_property === "address")).message || "" : ""
                    },
                    company_city:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "city")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "city")) > -1 ? this.props.errors.find(e => (e.invalid_property === "city")).message || "" : ""
                    },
                    company_postalCode:{
                            is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "postalCode")) > -1,
                            message: this.props.errors.findIndex(e => (e.invalid_property === "postalCode")) > -1 ? this.props.errors.find(e => (e.invalid_property === "postalCode")).message || "" : ""
                    },
                    company_country:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "country")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "country")) > -1 ? this.props.errors.find(e => (e.invalid_property === 'country')).message || "" : ""
                    },
                    company_krs:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "krs")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "krs")) > -1 ? this.props.errors.find(e => (e.invalid_property === 'krs')).message || "" : ""
                    },
                    company_nextContractDate:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "nextContractDate")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "nextContractDate")) > -1 ? this.props.errors.find(e => (e.invalid_property === 'nextContractDate')).message || "" : ""
                    },
                    company_pricing:{
                        is_invalid: this.props.errors.findIndex(e => (e.invalid_property === "pricing")) > -1,
                        message: this.props.errors.findIndex(e => (e.invalid_property === "pricing")) > -1 ? this.props.errors.find(e => (e.invalid_property === 'pricing')).message || "" : ""
                    },
                }
            }
        })
    }

    isInvalid = field => {
        return  ((this.state.errors_list[field] && this.state.errors_list[field] && this.state.errors_list[field].is_invalid) ? 'is-invalid':'' ) || ''
    }

    getError = field => {
        return  (this.state.errors_list[field] && this.state.errors_list[field] && this.state.errors_list[field].message) || ""
    }

    render(){
        return (

            <Card>
                <CardBody>
                    <Form>
                        <FormGroup className="row">
                            <Label className="col-lg-12 col-md-12">DANE FIRMY</Label>
                        </FormGroup>
                        <FormGroup className="row align-items-center">
                            <Label for="company_name" className="col-lg-3 col-md-3">Nazwa</Label>
                            <Col lg={9} md={9}>
                                <Input id="company_name" className={`form-control ${this.isInvalid('company_name')}`} onChange={(e)=>this.props.onChangeField(e,'company_name')} disabled={!this.props.edit} value={(this.props.company && this.props.company.name) || ''}/>
                                <div className="invalid-feedback">{this.getError('company_name')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_nip" className="col-lg-3 col-md-3">NIP</Label>
                            <Col lg={9} md={9}>
                                <Input  onChange={(e)=>this.props.onChangeField(e,'company_nip')} id="company_nip" className={`form-control ${this.isInvalid('company_nip')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.nip) || ''}/>
                                <div className="invalid-feedback">{this.getError('company_nip')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_phone" className="col-lg-3 col-md-3">Telefon</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_phone')} id="company_phone" className={`form-control ${this.isInvalid('company_phone')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.phone) || ''} type="number"/>
                                <div className="invalid-feedback">{this.getError('company_phone')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_email" className="col-lg-3 col-md-3">Email</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_email')} id="company_email" className={`form-control ${this.isInvalid('company_email')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.email) || ''} type="email"/>
                                <div className="invalid-feedback">{this.getError('company_email')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_address" className="col-lg-3 col-md-3">Adres</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_address')} id="company_address"  className={`form-control ${this.isInvalid('company_address')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.address) || ''} />
                                <div className="invalid-feedback">{this.getError('company_address')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_city" className="col-lg-3 col-md-3">Miasto</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_city')} id="company_city"  className={`form-control ${this.isInvalid('company_city')}`}  disabled={!this.props.edit} value={(this.props.company && this.props.company.city) || ''} />
                                <div className="invalid-feedback">{this.getError('company_city')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_postalCode"  className="col-lg-3 col-md-3">Kod pocztowy</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_postalCode')} id="company_postalCode"  className={`form-control ${this.isInvalid('company_postalCode')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.postalCode) || ''} />
                                <div className="invalid-feedback">{this.getError('company_postalCode')}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_country"  className="col-lg-3 col-md-3">Kraj</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_country')} id="company_country"  className={`form-control ${this.isInvalid('company_country')}`}  disabled={!this.props.edit} disabled={!this.props.edit} value={(this.props.company && this.props.company.country) || ''}/>
                                <div className="invalid-feedback">{this.getError('company_country')}</div>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>

                <CardBody>
                    <Form>
                        <FormGroup className="row">
                            <Label className="col-lg-12 col-md-12">SZCZEGÓŁY FIRMY</Label>
                        </FormGroup>


                        <FormGroup className="row align-items-center">
                            <Label for="company_status" className="col-lg-3 col-md-3">Status</Label>
                            <Col lg={9} md={9}>
                                <select onChange={(e)=>this.props.onChangeField(e,'company_status')} value={(this.props.company && this.props.company.status && this.props.company.status.id) || ((this.props.statuses && this.props.statuses[0]) || '')} id="company_status" className="form-control" disabled={!this.props.edit}>
                                     <option>Choose...</option>
                                    {
                                        (this.props.statuses|| [])
                                            .map((s)=>(
                                                <option key={s.id} value={s.id}>{s.name}</option>
                                            ))
                                    }
                                </select>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_type" className="col-lg-3 col-md-3">Typ</Label>
                            <Col lg={9} md={9}>
                                <select onChange={(e)=>this.props.onChangeField(e,'company_type')} value={(this.props.company && this.props.company.clientType && this.props.company.clientType.id) ||''} id="company_type" className="form-control" disabled={!this.props.edit}>
                                    <option>Choose...</option>
                                    {
                                        (this.props.clientTypes || [])
                                            .map((ct)=>(
                                                <option key={ct.id} value={ct.id}>{ct.name}</option>
                                            ))
                                    }
                                </select>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label  className="col-lg-3 col-md-3">Etykieta</Label>
                            <Col lg={9} md={9} className="tag-container">

                                <MultiSelectField
                                    options = {((this.props.tags) || []).map(t=>({value:`${t.id}`,label:t.name}))}
                                    name={'company_tags'}
                                    value={((this.props.company && this.props.company.companyTags ) || []).map(ct=>({value:`${ct.tag.id}`,label:ct.tag.name}))}
                                    onChange={(value)=>this.props.onChangeMultiTags(value)}

                                    placeholder={"etykiety"}
                                />


                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label className="col-lg-3 col-md-3">Grupa klientów</Label>
                            <Col lg={9} md={9} className="tag-container">

                                <MultiSelectField
                                    options = {((this.props.groups) || []).map(g=>({value:`${g.id}`,label:g.name}))}
                                    name={'company_groups'}
                                    value={((this.props.company && this.props.company.companyGroups ) || []).map(cg=>({value:`${cg.groups.id}`,label:cg.groups.name}))}
                                    onChange={(value)=>this.props.onChangeMultiGroups(value)}
                                    placeholder={"grupy klientów"}
                                />



                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_brand" className="col-lg-3 col-md-3">Branża</Label>
                            <Col lg={9} md={9}>
                                <select  onChange={(e)=>this.props.onChangeField(e,'company_brand')} value={(this.props.company && this.props.company.brand && this.props.company.brand.id) ||''} id="company_brand" className="form-control" disabled={!this.props.edit}>
                                    <option>Choose...</option>
                                    {
                                        (this.props.brands || [])
                                            .map((b)=>(
                                                <option key={b.id} value={b.id}>{b.name}</option>
                                            ))
                                    }
                                </select>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row align-items-center">
                            <Label for="company_krs" className="col-lg-3 col-md-3">KRS</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_krs')} id="company_krs" className={`form-control ${this.isInvalid('company_krs')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.krs) || ''}/>
                                <div className="invalid-feedback">{this.getError('company_krs')}</div>
                            </Col>
                        </FormGroup>


                    </Form>
                </CardBody>

                <CardBody>
                    <Form>
                        <FormGroup className="row">
                            <Label className="col-lg-12 col-md-12">SPRZEDAŻOWE</Label>
                        </FormGroup>
                        <FormGroup className="row align-items-center">
                            <Label for="next_contract_date" className="col-lg-3 col-md-3">Data nast. kontaktu</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_next_contract_date')} id="next_contract_date" className={`form-control ${this.isInvalid('company_nextContractDate')}`} disabled={!this.props.edit} value={(this.props.company && this.props.company.nextContractDate) || ''} type="date"/>
                                <div className="invalid-feedback">{this.getError('company_nextContractDate')}</div>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row align-items-center">
                            <Label for="company_pricing" className="col-lg-3 col-md-3">Stawka (PLN/H)</Label>
                            <Col lg={9} md={9}>
                                <Input onChange={(e)=>this.props.onChangeField(e,'company_pricing')} id="company_pricing" className={`form-control ${this.isInvalid('company_pricing')}`}  disabled={!this.props.edit} value={(this.props.company && this.props.company.pricing) || ''} type="number" />
                                <div className="invalid-feedback">{this.getError('company_pricing')}</div>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>




        )
    }

}