import {GROUP_ACTIONS} from "../actions/groupActions";


const initialState = {
    groups:[]
}


export default function(state = initialState, action){
    switch(action.type){

        case GROUP_ACTIONS.SET_ALL_GROUPS:

            return {
                ...state,
                groups: action.data
            }

        default:
            return state;
    }

}