import {Card, CardBody, Col, Form, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {Field, reduxForm} from "redux-form";
import {SelectField} from "../../../shared/components/form/Select";
import renderDropZoneField from "../../../shared/components/form/DropZone";
import Dropzone from "react-dropzone";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// const  newE = ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         removePlugins: [
//
//
//
//         ],
//         toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
//     } )
//     .catch( error => {
//         console.log( error );
//     } );
// //
//  ClassicEditor.builtinPlugins.map( plugin => console.log(plugin.pluginName) );
export default class TaskEditCard extends React.Component{


    constructor(props){
        super(props);

        this.state = {
            files:[]
        }
    }




    render(){
        return (

            <Card className={"task_edit_card"}>
                <CardBody>
                    <Form>
                        <FormGroup className="row">
                            <Label  className="col-lg-3 col-md-3">PODGLĄD</Label>
                        </FormGroup>
                    </Form>

                    <Form className="form form--horizontal">

                        <div className="form__form-group">
                                    <Input  onChange={(e)=>{this.props.onNameChange(e)}} type="textarea" value={this.props.task.name || ''}  id="task_name"  rows={10} />
                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label" style={{fontWeight:"bold"}}>Szczegóły</span>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Status</span>


                            {

                                false?
                                    (
                                        <svg className="load__icon">
                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                        </svg>
                                    )
                                    :
                                    (
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-input-wrap">
                                                <SelectField
                                                    name={"status"}

                                                    options={ (this.props.statuses || []).map(c => ({value:`${c.id}`, label:`${c.name}`})) }
                                                    value={ this.props.task && this.props.task.status && {value:`${this.props.task.status.id}`, label:`${this.props.task.status.name}`} || { value: '-1', label: 'Wybierz' }}
                                                    onChange={this.props.onStatusChange}
                                                />
                                            </div>
                                        </div>
                                    )

                            }

                        </div>



                        <div className="form__form-group">
                            <span className="form__form-group-label">Priorytet</span>


                            {

                                false?
                                    (
                                        <svg className="load__icon">
                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                        </svg>
                                    )
                                    :
                                    (
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-input-wrap">
                                                <SelectField
                                                    name={"priority"}

                                                    options={ (this.props.priorities || []).map(c => ({value:`${c.id}`, label:`${c.name}`})) }
                                                    value={ this.props.task && this.props.task.priority && {value:`${this.props.task.priority.id}`, label:`${this.props.task.priority.name}`} || { value: '-1', label: 'Wybierz' }}
                                                    onChange={this.props.onPriorityChange}
                                                />
                                            </div>
                                        </div>
                                    )

                            }

                        </div>



                        {/*<div className="form__form-group">*/}
                        {/*    <span className="form__form-group-label">Status</span>*/}
                        {/*    <div className="form__form-group-field">*/}
                        {/*        <div style={{display:"inline-block"}} className="actions_button_container"><a className="statusBadge"  href="#">{(this.props.task.status && this.props.task.status.name) || ''}</a></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        <div className="form__form-group">
                            <span className="form__form-group-label">Opis</span>
                            <div className="form__form-group-field">
                                {/*<Input  onChange={(e)=>{this.props.onDescriptionChange(e)}} type="textarea" value={this.props.task.description || ''}  id="task_description"  rows={10} />*/}


                                <CKEditor
                                    editor={ ClassicEditor }

                                    config={{

                                        removePlugins: [
                                                 'EasyImage','Image','ImageCaption','ImageStyle','ImageToolbar',
                                                         'CKFinderUploadAdapter',
                                                         'Autoformat',
                                                         'CKFinder',
                                                         'EasyImage',
                                                         'Image',
                                                         'ImageCaption',
                                                         'ImageStyle',
                                                         'ImageToolbar',
                                                         'ImageUpload',
                                                         'Indent',
                                                       //  'Link',
                                                         'MediaEmbed',
                                                       //  'Paragraph',
                                                         'PasteFromOffice',
                                                         'Table',
                                                         'TableToolbar',
                                                         'TextTransformation',
                                                         'BlockQuote'
                                        ]

                                    }}

                                    data={this.props.task.description || ''}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        //console.log( { event, editor, data } );
                                        this.props.onDescriptionChange(data)
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        //console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                       // console.log( 'Focus.', editor );
                                    } }
                                />


                            </div>
                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Załączniki</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <div className={`dropzone dropzone--single  dropzone--custom-height`} >
                                        <Dropzone
                                            customHeight={'200px'}
                                            accept="image/jpeg, image/png, application/pdf"
                                            name={"note_files"}
                                            multiple={true}
                                            onDrop={(fileToUpload) => {
                                                this.props.onDrop(fileToUpload);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()} className="dropzone__input">
                                                    {(!this.props.files || this.props.files.length === 0 || this.props.files_loading)
                                                    && (
                                                        <div className="file_placeholder" >

                                                            {
                                                                this.props.files_loading?
                                                                    (
                                                                        <svg className="load__icon">
                                                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                                                        </svg>
                                                                    )
                                                                    :
                                                                    (<>Upload file</>)
                                                            }

                                                        </div>
                                                    )}
                                                    <input {...getInputProps()} />
                                                </div>
                                            )}
                                        </Dropzone>
                                        {(this.props.files && Array.isArray(this.props.files) && this.props.files.length > 0 && this.props.files || []).map(f=>(
                                            <aside key={`${f.name}-${Math.random()}`} className="dropzone__img">

                                                <p className="dropzone__img-name">{f.name}</p>
                                                <button className="dropzone__img-delete" type="button" onClick={e => this.props.removeFile(f, e)}>
                                                    Remove
                                                </button>
                                            </aside>
                                        )) }

                                    </div>
                                </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"column"}}>
                                {
                                    ((this.props.task && this.props.task.attachments)  || [])
                                        .map(a=>(
                                            <div key={a.id}>
                                                <span className="lnr lnr-paperclip"> <a onClick={(e)=>this.props.downloadFile(e, a.id,a.name)} href="#">{a.name}</a></span>
                                            </div>

                                        ))

                                }


                            </div>

                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Data realizacji</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <Input value={this.props.task.deadline || ''} type="date" onChange={this.props.onDeadlineChange}   />
                                </div>
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Przypisane do</span>


                            {

                                false?
                                    (
                                        <svg className="load__icon">
                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                        </svg>
                                    )
                                    :
                                    (
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-input-wrap">
                                                <SelectField
                                                    name={"task_onwer"}

                                                    options={ (this.props.owners || []).map( o => ({value:`${o.id}`,label: `${o.name} ${o.surname}`})) }
                                                    value={ (this.props.task && this.props.task.owner) && {value:`${this.props.task.owner.id}`, label:`${this.props.task.owner.name} ${this.props.task.owner.surname}`} || { value: '-1', label: 'Wybierz' }}
                                                    onChange={this.props.onTaskOwnerChange}
                                                />
                                            </div>
                                        </div>
                                    )

                            }

                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label" style={{fontWeight:"bold"}}>Czas realizacji</span>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Estymowany</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <Input value={(this.props.task && this.props.task.estimatedTime) || ''} type="text" onChange={this.props.onEstimatedTimeChange}   />
                                </div>
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Rzeczywisty</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <Input value={(this.props.task && this.props.task.realTime) || ''} type="text" onChange={this.props.onRealTimeChange}   />
                                </div>
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label" style={{fontWeight:"bold"}}>Klient</span>
                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Firma</span>


                            {

                                this.props.company_loading?
                                    (
                                        <svg className="load__icon">
                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                        </svg>
                                    )
                                    :
                                    (
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-input-wrap">
                                                <SelectField
                                                    name={"company_name"}
                                                    isSearchable = {true}
                                                    options={ (this.props.companies || []).map(c => ({value:`${c.id}`, label:c.name})) }
                                                    value={ this.props.task && this.props.task.company && {value:`${this.props.task.company.id}`, label:`${this.props.task.company.name}`} || { value: '-1', label: 'Wybierz' }}
                                                    onChange={this.props.onCompanyChange}
                                                />
                                            </div>
                                        </div>
                                    )

                            }

                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Osoba kontaktowa</span>


                            {

                                this.props.contact_person_loading?
                                    (
                                        <svg className="load__icon">
                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                        </svg>
                                    )
                                    :
                                    (
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-input-wrap">
                                                <SelectField
                                                    name={"contact_person"}
                                                    isSearchable = {true}
                                                    options={ (this.props.contactPersons || []).map(c => ({value:`${c.id}`, label:`${c.name} ${c.surname} `})) }
                                                    value={ this.props.task && this.props.task.contactPerson && {value:`${this.props.task.contactPerson.id}`, label:`${this.props.task.contactPerson.name} ${this.props.task.contactPerson.surname}`} || { value: '-1', label: 'Wybierz' }}
                                                    onChange={this.props.onContactPersonChange}
                                                />
                                            </div>
                                        </div>
                                    )

                            }

                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label" style={{fontWeight:"bold"}}>Budżet</span>
                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Stawka</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <Input value={(this.props.task && this.props.task.rate) || ''} type="number" onChange={this.props.onRateChange}   />
                                </div>
                            </div>
                        </div>
                        <div className="form__form-group top_buttons_container" style={{marginTop:"20px"}}>
                            <div className="actions_button_container blue" ><a href="#" onClick={this.props.onSave} >Zapisz</a></div>
                            <div style={{marginLeft:'20px'}} className="actions_button_container red" ><a href="#" onClick={this.props.onDelete} >Usuń</a></div>
                        </div>

                    </Form>
                </CardBody>
            </Card>


        )
    }

}

