import React from 'react';
import {Card, CardBody, Col, Progress, Table} from 'reactstrap';
import {Link} from "react-router-dom";
import AnimatedProgressBar from "../../../UI/ProgressBars/components/AnimatedProgressBar";

const TableCard = ({tasks}) => (
    <Col md={12}>
        <Card>
            <CardBody>
                <Table responsive={true} striped={false}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nazwa</th>
                        <th>Status</th>
                        <th>Data realizacji</th>
                        <th>Klient</th>
                        <th>Estymowany czas</th>
                        <th>Przypisany</th>
                    </tr>
                    </thead>
                    <tbody>

                    {(tasks || []).map((t)=>(
                        <tr key={t.id}>

                            <td>{t.id}</td>
                            <td>{t.name}</td>
                            <td className="actions_button_container"><a className="statusBadge"  href="#">{t.status.name}</a></td>
                            <td>{t.deadline}</td>
                            <td>{(t.company && t.company.name) || 'brak'}</td>
                            <td>
                                <div>
                                    <div style={{textAlign:"center",fontWeight:"600"}}>
                                        {t.estimatedTime} h
                                    </div>
                                    <div>
                                        {
                                            t.estimatedTime > 0 ?
                                                (<Progress animated value={100*(t.realTime)/(t.estimatedTime)} />)
                                                :
                                                (null)
                                        }

                                    </div>
                                </div>

                            </td>
                            <td>{(t.owner && t.owner.name || '')+' '+(t.owner && t.owner.surname || '')}</td>



                        </tr>
                    ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </Col>
);

export default TableCard;
