import React from 'react';
import { Card, CardBody, Col ,Table } from 'reactstrap';
import {Link} from "react-router-dom";
import {stripHtml} from "../../../shared/functions/stripHtml";

const NotesListCard = ({notes,showDetails}) => (
    <Col md={12}>
        <Card>
            <CardBody>
                <Table responsive={true} striped={false}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Treść</th>
                        <th>Autor</th>
                        <th>Data powstania</th>
                        <th>Klient</th>
                        <th>Osoba kontaktowa</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>

                    {notes.map((n)=>{

                        const text = stripHtml(n.text);

                        return(
                            <tr key={n.id}>
                                <td>{n.id}</td>
                                <td>{text.length > 50? `${text.substring(0,50)}...` :text}</td>
                                <td>{n.creator && (n.creator.name+' '+n.creator.surname) || 'puste'}</td>
                                <td>{n.createdAt}</td>
                                <td>{n.company && n.company.name || 'puste'}</td>
                                <td>{n.contactPerson && n.contactPerson.name && n.contactPerson.surname &&  (n.contactPerson.name+' '+n.contactPerson.surname) || 'puste'}</td>


                                <td className="actions_button_container"><a href="#" onClick={(e)=>showDetails(e,n)}>Szczegóły</a></td>

                            </tr>
                    )})}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </Col>
);

export default NotesListCard ;
