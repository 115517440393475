import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const Buttons  = (props) => {

    return(
        <Row className="align-items-center justify-content-between top_buttons_container" >
            <Col md={6} lg={6} className="buttons_sub_container" >
                <div className="actions_button_container blue" ><a href="#" onClick={props.add} >Dodaj</a></div>
            </Col>
            <Col md={6} lg={6} className="buttons_sub_container"  >
                {/*<div className="actions_button_container yellow" ><Link to={"/"}>Tabela</Link></div>*/}
            </Col>
        </Row>
    )
}

export default Buttons;
