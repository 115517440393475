import {TAG_ACTIONS} from "../actions/tagActions";


const initialState = {
    tags:[]
}


export default function(state = initialState, action){
    switch(action.type){

        case TAG_ACTIONS.SET_ALL_TAGS:

            return {
                ...state,
                tags: action.data
            }

        default:
            return state;
    }

}