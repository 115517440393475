import {COMPANY_ACTIONS} from "../actions/companyActions";
import {NOTE_ACTIONS} from "../actions/noteActions";


const initialState = {
    selectedCompany: null,
    companies: [],
    errors:[],
    loading:false,
    success:false
}

export default function(state = initialState, action){
    switch(action.type){

        case COMPANY_ACTIONS.COMPANY_SET_SUCCESS:

            return {
                ...state,
                success: action.tof
            }

        case NOTE_ACTIONS.NOTE_REMOVE:

            if(state.selectedCompany === null || state.companies.length === 0){
                alert('error');
                return state;
            }

            let noteIndexInSelectedCompany = state.selectedCompany.notes.findIndex(n => parseInt(n.id) === parseInt(action.id))
            let notesWithoutDeleted =  [...state.selectedCompany.notes];
            notesWithoutDeleted.splice(noteIndexInSelectedCompany,1);
            console.log(notesWithoutDeleted);
            return {
                        ...state,
                        selectedCompany: {
                            ...state.selectedCompany,
                            notes: notesWithoutDeleted
                        },

            }

        case COMPANY_ACTIONS.COMPANY_SET_LOADING:

            return {...state,loading: action.tof}

        case COMPANY_ACTIONS.COMPANY_UPDATE_ONE:

            let indexOfUpdated = state.companies.findIndex((c)=>parseInt(c.id) === parseInt(action.company.id));

            return {...state, companies: state.companies.splice(indexOfUpdated,1,action.company)};

        case COMPANY_ACTIONS.COMPANY_SET_ERRORS:

            return {...state, errors: action.errors}

        case COMPANY_ACTIONS.COMPANY_ADD_NEW:

            return {...state,companies:[...state.companies,action.company]};

        case COMPANY_ACTIONS.COMPANY_SET_ONE:

            return {...state,selectedCompany: action.company};

        case COMPANY_ACTIONS.COMPANY_REMOVE:

            let newCompanies =  state.companies.filter(c=> parseInt(c.id) !== parseInt(action.id));
            console.log(newCompanies);

            return {...state, companies: newCompanies }

        case COMPANY_ACTIONS.COMPANY_SET_ALL:

            return {...state,companies: action.companies}

        default:
            return state

    }
}