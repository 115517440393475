import {URL} from "./constants";

export const STATUS_ACTIONS = {

    SET_ALL_STATUSES : 'SET_ALL_STATUSES',
}

export const statusesSetAll = (data)=>({
    type:STATUS_ACTIONS.SET_ALL_STATUSES,
    data
})

export const apiStatusesGetAll = () => {

    return dispatch => {

        fetch(`${URL}v1/statuses`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(statusesSetAll(data.data))

                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}