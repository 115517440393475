import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import ExamplePageOne from '../Example/index';
import ExamplePageTwo from '../ExampleTwo/index';
import Companies from "../Companies";
import CompanyDetails from "../CompanyDetails";
import NewCompany from "../CompanyDetails/NewCompany"
import Note from "../Notes"
import BasicForm from "../Form/BasicForm";

import ContactPersonDetails from "../ContactPersonDetails"
import NewContactPerson from "../ContactPersonDetails/NewContactPerson"
import ContactPersonNote from "../Notes/ContactPersonNote"
import ContactPersons from "../ContactPersons"
import Tasks from "../Tasks/index";
import TasksTable from "../Tasks/TasksTable";

import ProgressBars from "../../UI/ProgressBars"
import NotesList from "../Notes/NotesList";



const Pages = () => (
  <Switch>
    <Route path="/pages/one" component={ExamplePageOne} />
    <Route path="/pages/two" component={ExamplePageTwo} />
    <Route path="/pages/companies" component={Companies} />
    <Route path="/pages/new/company" component={NewCompany} />
    <Route path="/pages/company/:id" component={CompanyDetails} />

    <Route path="/pages/form" component={BasicForm} />

    <Route path="/pages/contact-person/:id" component={ContactPersonDetails} />
    <Route path="/pages/new/contact-person" component={NewContactPerson} />

    <Route exact path="/pages/new/note" component={Note} />
    <Route exact path="/pages/new/note/from-list" component={Note} />
    <Route path="/pages/new/note/:id" component={ContactPersonNote} />

    <Route path="/pages/contact-persons" component={ContactPersons} />

    <Route path="/pages/tasks/board" component={Tasks} />
    <Route path="/pages/tasks/table" component={TasksTable} />

    <Route path="/pages/notes" component={NotesList} />


  </Switch>
);

const wrappedRoutes = () => {

    if(localStorage.getItem('token') == null){
        window.location.href= '/log_in'
    }

    return(

        <div>
            <Layout/>
            <div className="container__wrap">
                <Route path="/pages" component={Pages}/>
                <Route exact path="/" component={Companies}/>
            </div>
        </div>
    )
};

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/progress_bars" component={ProgressBars} />
        <Route exact path="/log_in" component={LogIn} />

        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
