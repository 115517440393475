import {BRAND_ACTIONS} from "../actions/brandActions";


const initialState = {
    brands:[]
}


export default function(state = initialState, action){
    switch(action.type){

        case BRAND_ACTIONS.SET_ALL_BRANDS:

            return {
                ...state,
                brands: action.data
            }

        default:
            return state;
    }

}