import React, { Component, Fragment } from 'react';
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Header from "../../shared/header";
import EditCard from "./Components/EditCard";
import ListCard from "./Components/ListCard";
import Buttons from "./Components/Buttons";
import {connect} from "react-redux";
import {
    apiCompanyDelete,
    apiCompanyGetAll,
    apiCompanyGetOne,
    apiCompanyUpdate,
    companySetOne
} from "../../redux/actions/companyActions";
import {apiClientTypesGetAll} from "../../redux/actions/clientTypeActions";
import {apiTagsGetAll} from "../../redux/actions/tagActions";
import {apiGroupsGetAll} from "../../redux/actions/groupActions";
import {apiBrandsGetAll} from "../../redux/actions/brandActions";
import {apiStatusesGetAll} from "../../redux/actions/statusActions";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {Link, withRouter} from "react-router-dom";
import {apiNoteDelete} from "../../redux/actions/noteActions";
import NotificationSystem from 'rc-notification';
import {FullWideNotification} from "../../shared/components/Notification";
import {URL} from "../../redux/actions/constants";
import {downloadFile,arrayBufferToBase64} from '../../shared/functions/fileFunctions';
import ReactHtmlParser  from 'react-html-parser';

const mapStateToProps = (state) => {

    return {
        company: state.company.selectedCompany,
        errors: state.company.errors,
        success: state.company.success,
        companies: state.company.companies,
        clientTypes: state.clientTypes.clientTypes,
        tags:state.tags.tags,
        groups:state.groups.groups,
        brands:state.brands.brands,
        statuses:state.statuses.statuses,
        loading:state.company.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        propApiCompanyGetOne: (id)=> dispatch(apiCompanyGetOne(id)),
        propApiClientTypesGetAll: ()=> dispatch(apiClientTypesGetAll()),
        propApiTagsGetAll: ()=> dispatch(apiTagsGetAll()),
        propApiGroupsGetAll: ()=> dispatch(apiGroupsGetAll()),
        propApiBrandsGetAll: ()=> dispatch(apiBrandsGetAll()),
        propCompanySetOne: (company)=> dispatch(companySetOne(company)),
        propApiCompanyUpdate: (company)=> dispatch(apiCompanyUpdate(company)),
        propApiStatusesGetAll: ()=> dispatch(apiStatusesGetAll()),
        propApiCompanyDelete: (id)=> dispatch(apiCompanyDelete(id)),
        propApiNoteDelete: (id)=> dispatch(apiNoteDelete(id))
    }
}




export class CompanyDetailsDisconnected extends Component{

    showNoteDetailsHandler = (e,note) =>{
        e.preventDefault();
        this.setState({modalNote:note})
        this.toggleNoteModal();
    }

    onChangeMultiTagsHandler = (value)=>{

        let tags = (value || []).map(v=>(v.value))
        let companyTags = (value || []).map(v=>({tag:{id:v.value,name:v.label}}))
        this.props.propCompanySetOne({...this.props.company,tags,companyTags})
    }
    onChangeMultiGroupsHandler = (value) =>{

        let groups = (value || []).map(v=>(v.value))
        let companyGroups = (value || []).map(v=>({groups:{id:v.value,name:v.label}}))
        this.props.propCompanySetOne({...this.props.company,groups,companyGroups})

    }
    deleteCompanyHandler = e => {
        e.preventDefault();
        this.props.propApiCompanyDelete(this.props.company.id);

        this.setState({
            after_action:true
        })
    }
    deleteNote = (e) => {
        e.preventDefault()
        this.toggleNoteModal();
        this.props.propApiNoteDelete(this.state.modalNote.id);
        this.setState({
            after_action:true
        })

    }


    constructor(props){
        super(props);

        this.noti = null;

        this.state = {
            edit:false,
            modalNoteOpen:false,
            modalNote:null,
            after_action: false
        }
    }

    componentDidUpdate(prevProps) {

        if(this.props.companies === null || prevProps.companies == null){
            return;
        }


        //console.log(this.state.after_action);
        if(this.state.after_action){


            if(this.props.companies.length > prevProps.companies.length){
                this.showNotification('Firma została dodana prawidłowo','primary');
                setTimeout(()=>{
                    this.props.history.push('/pages/companies');
                },3000);
                this.setState({
                    after_action:false
                })
            }else  if(this.props.companies.length < prevProps.companies.length){
                this.showNotification('Firma została usunięta pomyślnie','primary');
                setTimeout(()=>{
                    this.props.history.push('/pages/companies');
                },3000);
                this.setState({
                    after_action:false
                })
            } else if(this.props.success && !prevProps.success){
                this.showNotification('Dane zostały pomyślnie zapisane','primary');
                setTimeout(()=>{
                    this.props.history.push('/pages/companies');
                },3000);
                this.setState({
                    after_action:false
                })
            }


            if((this.props.company && this.props.company.notes || []).length <  (prevProps.company && prevProps.company.notes || []).length ){
                this.showNotification('Notatka została usnięta pomyślnie','primary');
                this.setState({
                    after_action:false
                })
            }

        }



    }

    toggleNoteModal = ()=>{
        this.setState(prevState=>({modalNoteOpen:!prevState.modalNoteOpen}))
    }

    componentDidMount() {

        let id = this.props.match.params.id;
        this.props.propApiCompanyGetOne(id);
        this.props.propApiClientTypesGetAll();
        this.props.propApiTagsGetAll();
        this.props.propApiGroupsGetAll();
        this.props.propApiBrandsGetAll();
        this.props.propApiStatusesGetAll();

        NotificationSystem.newInstance({}, n => this.noti = n);
    }

    componentWillUnmount() {
        this.noti.destroy();
    }


    showNotification = (text,color) => {
        this.noti.notice({
            content: <FullWideNotification
                color={color}
                message={text}
            />,
            duration: 3,
            closable: true,
            style: { top: 0, left: 0,zIndex:1000,position:'fixed' },
            className: 'full',
        });
    }

    render() {

        if(this.props.loading){
            return (
                <div className={`load${this.props.loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }

        return(

            <Container className="dashboard">
                <Header subTitle='Szczegóły firmy' title={(this.props.company && this.props.company.name) || 'Szczegóły firmy'} />
                <Buttons onDeleteCompany={this.deleteCompanyHandler} editMode={this.state.edit} onEditClick={(e,tof)=>this.editClickHandler(e,tof)} onSaveClick={(e)=>this.saveClickHandler(e)} />
                <Row>
                    <Col xl={4}  lg={4} md={12} sm={12} xs={12}>
                        <EditCard
                            company={this.props.company}
                            errors={this.props.errors}
                            clientTypes={this.props.clientTypes}
                            tags = {this.props.tags}
                            groups = {this.props.groups}
                            brands = {this.props.brands}
                            statuses = {this.props.statuses}
                            edit = {this.state.edit}
                            onChangeField = {(e,fieldId)=>this.changeSelectedCompanyField(e,fieldId)}
                            onChangeMultiTags = {this.onChangeMultiTagsHandler}
                            onChangeMultiGroups = {this.onChangeMultiGroupsHandler}
                            showNotification = {this.showNotification}
                        />
                    </Col>

                    <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                        <ListCard
                            company={this.props.company}
                            showDetails = {this.showNoteDetailsHandler}
                        />
                    </Col>
                </Row>

                <Modal className="MMP-modal" isOpen={this.state.modalNoteOpen} toggle={this.toggleNoteModal}  backdrop={true} keyboard={true}>
                    <ModalHeader>
                        <span className={`sidebar__link-icon lnr lnr-layers`} /> Notatka nr {(this.state.modalNote && this.state.modalNote.id) || ''}
                    </ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardBody>
                                <Form>
                                    <FormGroup className="row">
                                        <Label className="col-lg-12 col-md-12">TREŚĆ NOTATKI</Label>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                            <CardBody>
                                {   ReactHtmlParser((this.state.modalNote && this.state.modalNote.text) || '' ) }
                            </CardBody>
                            <CardBody>
                                <Form>
                                    <FormGroup className="row">
                                        <Label className="col-lg-12 col-md-12">DANE FIRMY</Label>
                                    </FormGroup>
                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Nazwa firmy</Label>
                                        <Col lg={9} md={9}>
                                            <Input  className="form-control" disabled={true} value={(this.props.company && this.props.company.name) || ''}/>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Osoba kontaktowa</Label>
                                        <Col lg={9} md={9}>
                                            <Input  className="form-control" disabled={true} value={(this.state.modalNote && this.state.modalNote.contactPerson && this.state.modalNote.contactPerson.name+' '+this.state.modalNote.contactPerson.surname) || ''}/>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Data dodania</Label>
                                        <Col lg={9} md={9}>
                                            <Input  className="form-control" disabled={true} value={(this.state.modalNote && this.state.modalNote.createdAt) || ''}/>
                                        </Col>
                                    </FormGroup>


                                    <FormGroup className="row align-items-center">
                                        <Label  className="col-lg-3 col-md-3">Załączniki</Label>
                                        <Col lg={9} md={9}>
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                {
                                                    ((this.state.modalNote && this.state.modalNote.attachments)  || [])
                                                        .map(a=>(
                                                            <div key={a.id}>
                                                                <span className="lnr lnr-paperclip"> <a onClick={(e)=>this.downloadFile(e, a.id,a.name)} href="#">{a.name}</a></span>
                                                            </div>

                                                        ))

                                                }


                                            </div>
                                        </Col>
                                    </FormGroup>

                                </Form>
                            </CardBody>
                            <Row className="align-items-center justify-content-between top_buttons_container">
                                <Col md={6} lg={6} className="buttons_sub_container"  >
                                    <div className="actions_button_container red"><a href="#" onClick={this.deleteNote} >Usuń</a></div>
                                </Col>
                            </Row>
                        </Card>
                    </ModalBody>
                </Modal>
            </Container>

        )
    }

    editClickHandler(e,tof) {
        e.preventDefault();
        this.setState({edit:tof});
    }

    saveClickHandler(e) {
        e.preventDefault();
        this.props.propApiCompanyUpdate(this.props.company)
        this.setState({
            after_action:true
        })
    }

    changeSelectedCompanyField(e, fieldId) {
        e.preventDefault();
        let value = e.target.value;
        switch(fieldId){

            case 'company_name':
                this.props.propCompanySetOne({...this.props.company,name:value})
                break;

            case 'company_nip':
               // this.props.propCompanySetOne({...this.props.company,nip:parseInt(value)})
                this.props.propCompanySetOne({...this.props.company,nip:value})
                break;

            case 'company_phone':
                this.props.propCompanySetOne({...this.props.company,phone:value})
                break;

            case 'company_email':
                this.props.propCompanySetOne({...this.props.company,email:value})
                break;

            case 'company_address':
                this.props.propCompanySetOne({...this.props.company,address:value})
                break;

            case 'company_city':
                this.props.propCompanySetOne({...this.props.company,city:value})
                break;

            case 'company_postalCode':
                this.props.propCompanySetOne({...this.props.company,postalCode:value})
                break;

            case 'company_country':
                this.props.propCompanySetOne({...this.props.company,country:value})
                break;

            case 'company_krs':
                this.props.propCompanySetOne({...this.props.company,krs:value})
                break;

            case 'company_next_contract_date':
                this.props.propCompanySetOne({...this.props.company,nextContractDate:value})
                break;

            case 'company_pricing':
                this.props.propCompanySetOne({...this.props.company,pricing:value})
                break;

            case 'company_type':
                this.props.propCompanySetOne({...this.props.company,client_type_id:value, clientType:{...this.props.company.clientType,id:value}})
                break;

            case 'company_brand':
                this.props.propCompanySetOne({...this.props.company,brand_id:value, brand:{...this.props.company.brand,id:value}})
                break;

            case 'company_status':
                this.props.propCompanySetOne({...this.props.company,status_id:value, status:{...this.props.company.status,id:value}})
                break;

        }
    }

    downloadFile = (e,id,name) =>{
       downloadFile(e,id,name);
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CompanyDetailsDisconnected))
