import React, { Component, Fragment } from 'react';
import {Card, CardBody, Col, Table, CardHeader} from "reactstrap";
import {Link} from "react-router-dom";
import ContactPersonTable from "./ContactPersonTable";
import NotesTable from "./NotesTable";
import classNames from 'classnames';

export default class ListCard extends Component{

    constructor(props){
        super(props);

        this.state={
                tabId:1
        }

    }

   setTabId(e,id){

        e.preventDefault();

        this.setState({
            tabId:id
        })
   }

    render(){

        const navLinkClassContactPerson = classNames({
            "nav-link active": this.state.tabId === 1,
            "nav-link": this.state.tabId !== 1,
        });

        const navLinkClassNotes = classNames({
            "nav-link active": this.state.tabId === 2,
            "nav-link": this.state.tabId !== 2,
        });

        const navItemClassContactPerson = classNames({
            "nav-item active": this.state.tabId === 1,
            "nav-item": this.state.tabId !== 1,
        });

        const navItemClassNotes = classNames({
            "nav-item active": this.state.tabId === 2,
            "nav-item": this.state.tabId !== 2,
        });



        return (

            <Card className="details_left_list">
                <CardBody>
                    <CardHeader>
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className={navItemClassContactPerson}>
                                <a className={navLinkClassContactPerson} onClick={(e)=>this.setTabId(e,1)} href="#">Osoby kontaktowe
                                    <span className="badge badge-pill badge-primary">{(this.props.company && this.props.company.contactPeople && this.props.company.contactPeople.length) || 0 }</span>
                                </a>
                            </li>
                            <li className={navItemClassNotes} >
                                <a className={navLinkClassNotes} href="#" onClick={(e)=>this.setTabId(e,2)}>Notatki
                                    <span className="badge badge-pill badge-primary">{(this.props.company && this.props.company.notes && this.props.company.notes.length) || 0 }</span>
                                </a>

                            </li>
                        </ul>
                    </CardHeader>
                    {this.state.tabId === 1?(<ContactPersonTable company={this.props.company}/>):null }
                    {this.state.tabId === 2?(<NotesTable showDetails={this.props.showDetails} company={this.props.company}/>):null }

                </CardBody>
            </Card>

        )
    }

}