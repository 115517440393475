import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const Buttons  = (props) => {

    return(
        <Row className="align-items-center justify-content-between top_buttons_container">
            <Col md={6} lg={6} className="buttons_sub_container" >
                {
                    props.editMode?

                        ( <div className="actions_button_container blue"><a href="#" onClick={(e)=>props.onSaveClick(e)}>Zapisz</a></div>)

                        :

                        ( <div className="actions_button_container blue"><a href="#" onClick={(e)=>props.onEditClick(e,true)}>Edytuj</a></div>)
                }


                <div className="actions_button_container red"><a href="#" onClick={(e)=>props.onDeleteCompany(e)} >Usuń</a></div>
            </Col>
            <Col md={6} lg={6} className="buttons_sub_container"  >
                <div className="actions_button_container yellow"><Link to={"/pages/new/contact-person"}>Dodaj osobę kontaktową </Link></div>
                <div className="actions_button_container yellow"><Link to={"/pages/new/note"}>Dodaj notatkę </Link></div>
            </Col>
        </Row>
    )
}

export default Buttons;