import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Header from "../../shared/header";
import ButtonsNotes from "./Components/Buttons";
import {apiNoteDelete, apiNotesGetAll} from "../../redux/actions/noteActions";
import NotesListCard from "./Components/NotesListCard";
import NoteDetailsModal from "./Components/NoteDetailsModal";
import {downloadFile} from "../../shared/functions/fileFunctions";

import NotificationSystem from 'rc-notification';
import {FullWideNotification} from "../../shared/components/Notification";
import {withRouter} from "react-router";



const mapStateToProps = (state) => {

    return {
        notes_loading: state.notes.loading_notes,
        notes: state.notes.notes

    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        propApiNotesGetAll: ()=> dispatch(apiNotesGetAll()),
        propApiNoteDelete: (id)=> dispatch(apiNoteDelete(id,null,true))
    }
}


class NotesList extends Component{

    onNoteAddHandler = (e)=>{
        e.preventDefault();
        this.props.history.push('/pages/new/note/from-list');
    };


    constructor(props){
        super(props);

        this.noti = null;

        this.state = {
            edit:false,
            modalNoteOpen:false,
            modalNote:null,
            after_action: false
        }
    }

    componentWillUnmount() {
        this.noti.destroy();
    }

    showNotification = (text,color) => {
        this.noti.notice({
            content: <FullWideNotification
                color={color}
                message={text}
            />,
            duration: 3,
            closable: true,
            style: { top: 0, left: 0,zIndex:1000,position:'fixed' },
            className: 'full',
        });
    }


    componentDidUpdate(prevProps) {


        if(this.state.after_action){

            if((this.props.notes || []).length <  (prevProps.notes  || []).length ){
                this.showNotification('Notatka została usunięta pomyślnie','primary');
                this.setState({
                    after_action:false
                })
            }

        }

    }



    deleteNoteHandler = (e) => {
        e.preventDefault()
        this.toggleNoteModal();
        this.props.propApiNoteDelete(this.state.modalNote.id);
        this.setState({
            after_action:true
        })

    }


    showNoteDetailsHandler = (e,note) =>{
        e.preventDefault();
        this.setState({modalNote:note})
        this.toggleNoteModal();
    }

    componentDidMount() {

        this.props.propApiNotesGetAll();

        NotificationSystem.newInstance({}, n => this.noti = n);
    }

    toggleNoteModal = ()=>{
        this.setState(prevState=>({modalNoteOpen:!prevState.modalNoteOpen}))
    }


    downloadFile = (e,id,name) =>{
        downloadFile(e,id,name);
    };

    render() {

        if(this.props.notes_loading){
            return (
                <div className={`load${this.props.notes_loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }



        return(

            <Container className="dashboard">
                <Header subTitle={"Lista wszystkich notatek znajdujących się w biznesowym ekosystemie MuchMore."} title={"Notatki"} />
                <ButtonsNotes onNoteAdded = {this.onNoteAddHandler} />
                <Row>
                    <NotesListCard notes={this.props.notes} showDetails = {this.showNoteDetailsHandler} />
                </Row>

                <NoteDetailsModal downloadFile={this.downloadFile} note={this.state.modalNote} deleteNote={this.deleteNoteHandler} isOpen={this.state.modalNoteOpen} toggleNoteModal={this.toggleNoteModal}  />

            </Container>

        )
    }


}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NotesList))
