import {Card, CardBody, Col, Form, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {Field, reduxForm} from "redux-form";
import {SelectField} from "../../../shared/components/form/Select";
import renderDropZoneField from "../../../shared/components/form/DropZone";
import Dropzone from "react-dropzone";


export default class NoteAddition extends React.Component{


    constructor(props){
        super(props);

        this.state = {
            files:[]
        }
    }




    render(){
        return (

            <Card>
                <CardBody>
                    <Form>
                        <FormGroup className="row">
                            <Label  className="col-lg-3 col-md-3">SZCZEGÓŁY</Label>
                        </FormGroup>
                    </Form>

                    <Form className="form form--horizontal">

                        <div className="form__form-group">
                            <span className="form__form-group-label">Nazwa firmy</span>


                                    {

                                        this.props.company_loading?
                                            (
                                                        <svg className="load__icon">
                                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                                        </svg>
                                            )
                                            :
                                            (
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-input-wrap">
                                                        <SelectField
                                                            name={"company_name"}

                                                            options={ (this.props.companies || []).map(c => ({value:`${c.id}`, label:c.name})) }
                                                            value={ this.props.selectedCompany && {value:`${this.props.selectedCompany.id}`, label:`${this.props.selectedCompany.name}`} || { value: '-1', label: 'Wybierz' }}
                                                            onChange={this.props.companyChange}
                                                        />
                                                    </div>
                                                </div>
                                            )

                                    }


                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Osoba kontaktowa</span>

                            {
                                this.props.contact_person_loading?
                                    (
                                        <svg className="load__icon">
                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                        </svg>
                                    )
                                    :
                                    (

                                        <div className="form__form-group-field">
                                            <div className="form__form-group-input-wrap">
                                                <SelectField
                                                    name={"contact_person_name"}

                                                    options={ [...(this.props.selectedCompany && this.props.selectedCompany.contactPeople || []).map(p => ({value:`${p.id}`, label: `${p.name} ${p.surname}`})),{value:null,label:'brak'}] }
                                                    value={ this.props.selectedContactPerson && {value:`${this.props.selectedContactPerson.id}`, label:`${this.props.selectedContactPerson.name} ${this.props.selectedContactPerson.surname}`} || { value: '-1', label: 'Wybierz' }}
                                                    onChange={this.props.contactPersonChange}
                                                />
                                            </div>
                                        </div>

                                    )

                            }

                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Data dodania</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <Input value={this.props.note.createdAt || ''} type="date" onChange={this.props.createdAtChange}   />
                                </div>
                            </div>
                        </div>


                        <div className="form__form-group">
                            <span className="form__form-group-label">Upload</span>
                            <div className="form__form-group-field">

                                <div className="form__form-group-input-wrap">
                                    <div className={`dropzone dropzone--single  dropzone--custom-height`}>
                                        <Dropzone
                                            accept="image/jpeg, image/png, application/pdf"
                                            name={"note_files"}
                                            multiple={true}
                                            onDrop={(fileToUpload) => {
                                                this.props.onDrop(fileToUpload);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()} className="dropzone__input">
                                                    {(!this.props.files || this.props.files.length === 0 || this.props.files_loading)
                                                    && (
                                                        <div className="file_placeholder">

                                                            {
                                                                this.props.files_loading?
                                                                    (
                                                                        <svg className="load__icon">
                                                                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                                                        </svg>
                                                                    )
                                                                    :
                                                                    (<>Upload file</>)
                                                            }

                                                        </div>
                                                    )}
                                                    <input {...getInputProps()} />
                                                </div>
                                            )}
                                        </Dropzone>
                                        {(this.props.files && Array.isArray(this.props.files) && this.props.files.length > 0 && this.props.files || []).map(f=>(
                                            <aside key={`${f.name}-${Math.random()}`} className="dropzone__img">

                                                <p className="dropzone__img-name">{f.name}</p>
                                                <button className="dropzone__img-delete" type="button" onClick={e => this.props.removeFile(f, e)}>
                                                    Remove
                                                </button>
                                            </aside>
                                        )) }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </CardBody>
            </Card>


        )
    }

}

// export default reduxForm({
//     form: '',
// })((NoteAddition));