import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";

import {Link, withRouter} from 'react-router-dom';

const Header  = ({title,subTitle}) => {


    let bc = null


    if(location.pathname &&  location.pathname.includes('/pages/companies')){
        bc =  (<p> <Link to={'/pages/companies'}>Firmy </Link></p>);

    }else if(location.pathname &&  location.pathname.includes('/pages/new/company')){
        bc =  (<p> <Link to={'/pages/companies'}>Firmy </Link> / Tworzenie firmy</p>);

    }else if(location.pathname &&  location.pathname.includes('/pages/company/')){
        bc =  (<p> <Link to={'/pages/companies'}>Firmy </Link> / Szczegóły firmy</p>);

    }else if(location.pathname &&  location.pathname.includes('/pages/contact-persons')){
        bc =  (<p> <Link to={'/pages/contact-persons'}>Osoby kontaktowe </Link> </p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/new/contact-person')){
        bc =  (<p> <Link to={'/pages/contact-persons'}>Osoby kontaktowe </Link> / Tworzenie osoby</p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/contact-person/')){
        bc =  (<p> <Link to={'/pages/contact-persons'}>Osoby kontaktowe </Link> / Szczegóły osoby</p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/new/note')){
        bc =  (<p> <Link to={'/pages/notes'}>Notatki </Link> / Tworzenie notatki</p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/new/note/')){
        bc =  (<p> <Link to={'/pages/notes'}>Notatki </Link> / Tworzenie notatki</p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/tasks/board')){
        bc =  (<p> Zadania / Lista </p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/tasks/table')){
        bc =  (<p> Zadania / Tabela </p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/notes')){
        bc =  (<p> <Link to={'/pages/notes'}>Notatki </Link></p>);
    }
    else if(location.pathname &&  location.pathname.includes('/pages/notes/from-list')){
        bc =  (<p> <Link to={'/pages/notes'}>Notatki </Link></p>);
    }




        return(
            <Row className="align-items-center justify-content-between">
                <Col md={6} lg={6} >
                    <h3   className="page-title">{title}</h3>
                    <h4>{subTitle}</h4>
                </Col>
                <Col md={2} lg={2}  >
                    <div className="mm-breadcrumb">
                    {bc}
                    </div>
                </Col>
            </Row>
        )
}

export default withRouter(Header);
