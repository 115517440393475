import {URL} from './constants';

export const COMPANY_ACTIONS = {

    API_COMPANY_GET_ALL : 'API_COMPANY_GET_ALL',
    API_COMPANY_GET_ONE : 'API_COMPANY_GET_ONE',
    COMPANY_SET_ONE : 'COMPANY_SET_ONE',
    COMPANY_SET_ALL : 'COMPANY_SET_ALL',
    COMPANY_REMOVE : 'COMPANY_REMOVE',
    COMPANY_ADD_NEW : 'COMPANY_ADD_NEW',
    COMPANY_SET_ERRORS: 'COMPANY_SET_ERRORS',
    COMPANY_UPDATE_ONE: 'COMPANY_UPDATE_ONE',
    COMPANY_SET_LOADING: 'COMPANY_SET_LOADING',
    COMPANY_SET_SUCCESS: 'COMPANY_SET_SUCCESS'
};

export const companySetSuccess = tof => ({
    type: COMPANY_ACTIONS.COMPANY_SET_SUCCESS,
    tof,
});

export const companySetLoading = tof => ({
    type: COMPANY_ACTIONS.COMPANY_SET_LOADING,
    tof,
});


export const companyUpdateOne = company => ({
    type: COMPANY_ACTIONS.COMPANY_UPDATE_ONE,
    company,
});

export const companySetErrors = errors => ({
    type: COMPANY_ACTIONS.COMPANY_SET_ERRORS,
    errors,
});

export const companySetOne = company => ({
  type: COMPANY_ACTIONS.COMPANY_SET_ONE,
  company,
});

export const companyAddNew = company => ({
  type: COMPANY_ACTIONS.COMPANY_ADD_NEW,
  company,
});

export const companySetAll = companies => ({
  type: COMPANY_ACTIONS.COMPANY_SET_ALL,
  companies,
});

export const companyRemove = id => ({
  type: COMPANY_ACTIONS.COMPANY_REMOVE,
  id,
});


export const apiCompanyGetAll = () => {

    return dispatch => {

        dispatch(companySetLoading(true))
        fetch(`${URL}v1/companies`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            dispatch(companySetLoading(false))

            if (response.status === 200 && response.headers.get("content-type") === 'application/json') {

                response.json().then(data => {

                    dispatch(companySetAll(data.data))


                }).catch(error => {
                    alert('error');
                })

            }else if(response.status === 401){
                //nothing intercept to login page
            }else{
                    dispatch(companySetLoading(false))
                    alert("error");
                }
            })
    }
}


export const apiCompanyGetOne = (id) => {

    return dispatch => {

        dispatch(companySetLoading(true))
        fetch(`${URL}v1/company/${id}`,{

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }


        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(companySetOne(data.data))
                    dispatch(companySetLoading(false))

                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}



export const apiCompanyCreateNew = (companyN) => {

    let company = {...companyN}

    return dispatch => {

        dispatch(companySetLoading(true))
        dispatch(companySetSuccess(false))
        dispatch(companySetErrors([]));


        fetch(`${URL}v1/company`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(company)

        }).then( response => {

            dispatch(companySetLoading(false))

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                response.json().then(data=>{

                    dispatch(companyAddNew(company))
                    dispatch(apiCompanyGetAll())
                    dispatch(companySetSuccess(true));

                }).catch(error=>{alert('error');})

            }else if(response.status === 400){

                response.json().then(data=>{

                    dispatch(companySetErrors(data.errors));

                }).catch(error=>{alert('error');})

            }else if(response.status === 404){

                response.json().then(data=>{

                    dispatch(companySetErrors([{invalid_property:'global',message:data.data}]));

                }).catch(error=>{alert('error');})

            }else{

                alert("error");
            }
        })
    }
}

export const apiCompanyUpdate = (company) => {

    return dispatch => {

        dispatch(companySetLoading(true))
        dispatch(companySetSuccess(false))
        dispatch(companySetErrors([]));

        fetch(`${URL}v1/company`,{

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(company)

        }).then( response => {

            dispatch(companySetLoading(false))

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){
                dispatch(companySetSuccess(true))
                dispatch(companyUpdateOne(company));

            }else if(response.status === 400){

                response.json().then(data=>{

                    dispatch(companySetErrors(data.errors));

                }).catch(error=>{alert('error');})

            }else if(response.status === 404){

                response.json().then(data=>{

                    dispatch(companySetErrors([{invalid_property:'global',message:data.data}]));

                }).catch(error=>{alert('error');})

            }else{
                dispatch(companySetLoading(false))
                alert("error");
            }
        })
    }
}

export const apiCompanyDelete = (id) => {

    return dispatch => {

        dispatch(companySetLoading(true))

        fetch(`${URL}v1/company/${id}`,{

            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

        }).then( response => {

            if(response.status === 200 && response.headers.get("content-type") === 'application/json'){

                dispatch(companyRemove(id));
                dispatch(companySetLoading(false))
                dispatch(apiCompanyGetAll())

              //  alert('company has been removed');

            }else{

                alert("error");
            }
        })
    }
}


