import React, { Component, Fragment } from 'react';
import {Card, CardBody, Col, Table, Form,FormGroup,Label,Input,FormText} from "reactstrap";
import {Link} from "react-router-dom";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

export default class EditCard extends Component{


    render(){
        return (

            <Card>
                <CardBody>
                    <Form>
                        <FormGroup className="row">
                            <Label  className="col-lg-3 col-md-3">DODAWANIE NOTATKI</Label>
                        </FormGroup>

                        <FormGroup className="row">
                            <Label for="note_text" className="col-lg-3 col-md-3">Notatka</Label>
                            <Col lg={9} md={9}>
                                {/*<Input  onChange={(e)=>{this.props.onNoteChange(e)}} type="textarea" value={this.props.note.text}  id="note_text"  rows={10} />*/}

                                <CKEditor
                                    editor={ ClassicEditor }

                                    config={{

                                        removePlugins: [
                                        //    'EasyImage','Image','ImageCaption','ImageStyle','ImageToolbar',
                                            'CKFinderUploadAdapter',
                                            'Autoformat',
                                            'CKFinder',
                                            'EasyImage',
                                            'Image',
                                            'ImageCaption',
                                            'ImageStyle',
                                            'ImageToolbar',
                                            'ImageUpload',
                                            // 'Indent',
                                            // 'Link',
                                             'MediaEmbed',
                                            // 'Paragraph',
                                            // 'PasteFromOffice',
                                            // 'Table',
                                            // 'TableToolbar',
                                            // 'TextTransformation',
                                            // 'BlockQuote'
                                        ]

                                    }}

                                    data={this.props.note.text || ''}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        //console.log( { event, editor, data } );
                                        this.props.onNoteChange(data)
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        //console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        // console.log( 'Focus.', editor );
                                    } }
                                />

                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>


        )
    }

}
