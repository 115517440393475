import React, { Component, Fragment, PureComponent } from 'react';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const ButtonsCreateMode  = (props) => {

    return(
        <Row className="align-items-center justify-content-between top_buttons_container">
            <Col md={6} lg={6} className="" >
                <div className="actions_button_container blue"><a href="#" onClick={(e)=>props.onSaveClick(e)}>Zapisz</a></div>
            </Col>

        </Row>
    )
}

export default ButtonsCreateMode;